<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { createCssVar } from "@/views/components/whitelabel-templates/common/utils/createCssVar";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import KBHeaderComponent from "./components/common/KBHeader.vue";
import KBGoTop from "./components/common/KBGoTop.vue";
import KBSidebar from "./components/common/KBSidebar.vue";
import KBMobileBar from "./components/common/KBMobileBar.vue";
import KBFooter from "./components/common/KBFooter.vue";
import KBHomePage from "@/views/components/whitelabel-templates/KB/views/homePages/KBHomePage.vue";
import KBLogin from "@/views/components/whitelabel-templates/KB/components/common/KBLogin.vue";
import KBRegister from "@/views/components/whitelabel-templates/KB/components/common/KBRegister.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";

const MODULE_KB_STORE_NAME = "kb-store";
const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "KBLayout",
  components: {
    UserProfile,
    KBRegister,
    KBLogin,
    KBHomePage,
    KBHeaderComponent,
    KBGoTop,
    KBSidebar,
    KBMobileBar,
    KBFooter,
  },
  setup() {
    // Register modules
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const el = ref(null);

    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );
    const preview = computed(() => store.state["kb-store"].preview);

    // Computed properties for configs
    const loginConfig = computed(() => editTemplate.value.templateData.login);
    const registerConfig = computed(
      () => editTemplate.value.templateData.register
    );
    const headerConfig = computed(() => editTemplate.value.templateData.header);
    const sidebarConfig = computed(
      () => editTemplate.value.templateData.sidebar
    );
    const adsConfig = computed(() => editTemplate.value.templateData.ads);
    const actionCardsConfig = computed(
      () => editTemplate.value.templateData.call_to_action_card
    );
    const categoriesConfig = computed(
      () => editTemplate.value.templateData.categories
    );
    const footerConfig = computed(() => editTemplate.value.templateData.footer);

    // Computed properties for colors
    const mainColors = computed(
      () => editTemplate.value.templateData.main.colors
    );
    const userProfileColors = computed(
      () => editTemplate.value.templateData.user_profile.colors
    );

    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--primary-color": mainColors.value.primaryColor,
        "--title-color": mainColors.value.titleColor,
        "--text-color": mainColors.value.textColor,
        "--sub-text-color": mainColors.value.subTextColor,
        "--placeholder-color": mainColors.value.placeholderColor,
        "--background-color": mainColors.value.backgroundColor,
        "--standard-color": mainColors.value.standardColor,
        "--shadow-color": mainColors.value.shadowColor,
        "--page-shadow": mainColors.value.pageShadow,
        "--autofill-color": mainColors.value.autofillColor,
        "--yellow-color": mainColors.value.yellowColor,
        "--green-color": mainColors.value.greenColor,
        "--white-01": mainColors.value["white-01"],
        "--btn-active-color": mainColors.value.btnActiveColor,
        "--negative-color": mainColors.value.negativeColor,
        "--positive-color": mainColors.value.positiveColor,
        "--layout-header-bg": mainColors.value.header_background_color,
        "--layout-header-btn-color": mainColors.value.header_btn_color,
        "--layout-header-btn-bg": mainColors.value.header_btn_background_color,
        "--layout-header-btn-bg-hover":
          mainColors.value.header_btn_background_color_hover,
        "--layout-sidebar-bg": mainColors.value.sidebar_background_color,
        "--layout-sidebar-btn-color": mainColors.value.sidebar_btn_color,
        "--layout-sidebar-btn-bg":
          mainColors.value.sidebar_btn_background_color,
        "--layout-sidebar-btn-bg-hover":
          mainColors.value.sidebar_btn_background_color_hover,
        "--setting-bg-primary": userProfileColors.value.bgPrimary,
        "--setting-bg-secondary": userProfileColors.value.bgSecondary,
        "--setting-border": userProfileColors.value.colorBorder,
        "--setting-color-primary": userProfileColors.value.colorPrimary,
        "--setting-color-primary-text":
          userProfileColors.value.colorPrimaryText,
        "--setting-color-secondary": userProfileColors.value.colorSecondary,
      });
    };

    watch([mainColors, userProfileColors], setCssVar, {
      deep: true,
      immediate: true,
    });

    onMounted(setCssVar);

    return {
      el,
      preview,
      editTemplate,
      loginConfig,
      registerConfig,
      headerConfig,
      sidebarConfig,
      adsConfig,
      actionCardsConfig,
      categoriesConfig,
      footerConfig,
      mainColors,
      userProfileColors,
    };
  },
};
</script>

<template>
  <div class="kb-layout" ref="el" :class="{ open: false }">
    <KBGoTop />
    <KBLogin :login_config="loginConfig" />
    <KBRegister :register_config="registerConfig" />
    <KBHeaderComponent :header="headerConfig" />
    <KBSidebar :sidebar="sidebarConfig" />
    <KBMobileBar />
    <div class="kb-container">
      <div class="kb-content">
        <user-profile v-show="preview === 'profile'" />

        <k-b-home-page
          v-show="preview === 'general'"
          :ads="adsConfig"
          :actionCards="actionCardsConfig"
          :categories="categoriesConfig"
        />
      </div>

      <KBFooter :footer="footerConfig" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../common/assets/mixins";

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: var(--text-color);
  scroll-behavior: smooth;

  * {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: thin;
  }
}

.btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.kb {
  &-layout {
    --transition: 0.15s ease-in-out;
    --transition-slow: 0.7s cubic-bezier(0.42, 0, 0.03, 1);
    --radius: 0.25rem;
    --header-height: 80px;
    --gap: 10px;
    --gap-big: 20px;
    background: var(--background-color);
    display: grid;
    grid-template-columns: 1fr;
    transition: var(--transition);
    width: 100%;

    @include responsive(lg) {
      grid-template-columns: var(--header-height) 1fr;
      --gap: 10px;
      --gap-big: 40px;
    }

    &.open {
      @include responsive(lg) {
        grid-template-columns: calc(var(--header-height) * 4) 1fr;
      }
    }
  }

  &-container {
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    gap: var(--gap-big);
    padding: calc(calc(var(--header-height) * 2) + 10px) 0
      calc(var(--header-height) + 50px) 0;

    @include responsive(lg) {
      height: calc(100dvh - var(--header-height));
      padding: 0 0 0 0;
      overflow: auto;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-big);
    width: 100%;

    & > div:first-of-type {
      @include responsive(lg) {
        padding-top: 50px;
      }
    }
  }

  &-filters {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    padding: 0 10px;
    width: 100vw;

    @include responsive(lg) {
      padding: 0 50px;
      width: auto;
    }

    &-search {
      display: flex;
      align-items: center;
      width: 100%;
      height: 2.75rem;
      background-color: var(--standard-color);
      border: 1px solid var(--white-01);
      border-radius: var(--radius);
      margin: 0;

      &-icon {
        width: 1.4em;
        height: 1.4em;
        margin: 0 20px;
        fill: var(--text-color);

        svg {
          width: 100%;
          height: 100%;
        }
      }

      input {
        width: 100%;
        background: none;
        border: none;
        color: var(--text-color);

        &:focus {
          outline: none;
        }
      }
    }

    &-categories {
      display: flex;
      gap: var(--gap);
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include responsive(lg) {
        flex-wrap: wrap;
        overflow: visible;
      }

      &-item {
        display: flex;
        align-items: center;
        background-color: var(--standard-color);
        border-radius: var(--radius);
        padding: 10px 20px;
        gap: 8px;
        fill: var(--text-color);
        color: var(--text-color);
        cursor: pointer;
        transition: var(--transition);
        min-width: max-content;

        &:hover {
          background-color: var(--btn-active-color);
        }

        &.active {
          background-color: var(--btn-active-color);
          color: white;
          fill: white;
          font-weight: 600;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}
</style>
