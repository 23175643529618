<script>
/* eslint-disable */
import KBGame from "./KBGame.vue";

export default {
  data() {
    return {
      loadingGames: false,
      activeIndex: 0,
      swiperInstance: null,
      games: [
        {
          id: 1,
          name: "Juego 1",
          image: "2cb71cb8b3.jpg",
        },
        {
          id: 2,
          name: "Juego 2",
          image: "4db88d14-3730-4a7b-863b-3ea6a320ed26.jpg",
        },
        {
          id: 3,
          name: "Juego 3",
          image: "57d44e7a-185d-494e-a96d-9f9f1e812d74.jpg",
        },
        {
          id: 4,
          name: "Juego 4",
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
        },
        {
          id: 5,
          name: "Juego 5",
          image: "0205e2c1-b66d-45c1-b628-ddbfa78f5181.jpg",
        },
        {
          id: 6,
          name: "Juego 6",
          image: "907b5393-aab7-4fb1-bafc-3c855428e1cc.jpg",
        },
        {
          id: 7,
          name: "Juego 7",
          image: "ae2cda34-3357-41ec-b5ae-32cefd4afebd.jpg",
        },
        {
          id: 8,
          name: "Juego 8",
          image: "b743a65d-d10b-4ccb-8fb8-4377119dded9.jpg",
        },
        {
          id: 9,
          name: "Juego 9",
          image: "d3e67e6b-d882-4d34-8ff3-a4ff6c388d4c.jpg",
        },
        {
          id: 10,
          name: "Juego 10",
          image: "e6b97935-8f05-4f81-b5dc-2534404e94d1.jpg",
        },
        {
          id: 11,
          name: "Juego 11",
          image: "eb557ede-b7cd-44e4-b52f-05e68c0048b5.jpg",
        },
        {
          id: 12,
          name: "Juego 12",
          image: "f6acadc0-8961-4ebb-bf71-ba676469901d.jpg",
        },
        {
          id: 13,
          name: "Juego 13",
          image: "f76d3f4a-9712-4bd1-9426-f3ab7069b6a2.jpg",
        },
        {
          id: 14,
          name: "Juego 14",
          image: "ff0b0511-1f48-43d1-8dd3-294bb6437b4b.jpg",
        },
      ],
    };
  },
  mounted() {
    this.swiperInstance = new this.$root.$swiper(this.$refs.swiperGames, {
      autoHeight: true,
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 10,
      speed: 800,
      loopFillGroupWithBlank: true,
      loop: true,
      breakpoints: {
        992: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
      },
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
  methods: {
    nextSlide() {
      this.swiperInstance.slideNext();
    },
    prevSlide() {
      this.swiperInstance.slidePrev();
    },
  },
  components: {
    KBGame,
  },
};
</script>

<template>
  <div class="kb-games">
    <div class="kb-games-header">
      <div class="kb-games-header-title">The best of the best games</div>
      <!--Loading state-->
      <div v-if="!loadingGames" class="kb-games-header-actions">
        <a class="kb-games-header-actions-all"> See all </a>
        <div
          @click="prevSlide"
          class="kb-games-header-actions-prev btn btn-dark"
        >
          <svg viewBox="0 0 32 32">
            <path
              d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
            ></path>
          </svg>
        </div>
        <div
          @click="nextSlide"
          class="kb-games-header-actions-next btn btn-dark"
        >
          <svg viewBox="0 0 32 32">
            <path
              d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
            ></path>
          </svg>
        </div>
      </div>
    </div>

    <!--Loading state-->
    <div v-if="loadingGames" class="flex justify-center p-4 w-full">
      <b-spinner small></b-spinner>
    </div>
    <div v-else ref="swiperGames" class="swiper kb-games-swiper">
      <div class="swiper-wrapper">
        <KBGame
          class="swiper-slide"
          v-for="game in games"
          :key="game.id"
          :game="game"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.kb-games {
  max-width: 100vw;
  padding: 0 10px;
  margin-top: 30px;

  @include responsive(lg) {
    width: 100%;
    padding: 0 50px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.05rem;
    margin-bottom: 15px;
    color: var(--title-color);

    &-title {
      font-weight: 600;

      @include responsive(lg) {
        font-size: 1.25rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 5px;

      &-all {
        color: var(--primary-color);
        text-decoration: none;
        font-size: 0.875rem;
        padding: 0 10px;
        cursor: pointer;
        min-width: max-content;
      }

      &-prev,
      &-next {
        border: none;
        display: none;
        justify-content: center;
        align-items: center;
        opacity: 0.3;

        @include responsive(lg) {
          display: flex;
        }

        svg {
          width: 12px;
          height: 19px;

          path {
            fill: white;
          }
        }
      }

      &-prev {
        svg {
          rotate: -180deg;
        }
      }

      &-button-next {
        left: 100%;
      }
    }
  }
}
</style>
