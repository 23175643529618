<script>
export default {
  name: "KBVictory",
  props: {
    victory: {}
  },
  computed: {
    valueShort() {
      let p = 1;
      let sign = 'US$';

      if (this.victory.value > 1000 && this.victory.value < 1000000) {
        p = 1000;
        sign = 'K$';
      }

      if (this.victory.value > 1000000) {
        p = 1000000
        sign = 'M$';
      }

      return (this.victory.value / p).toFixed(2) + sign;
    },
    badge() {
      switch (this.victory.badge) {
        case 'diamond': return '/img/kingboxplus/diamond_mini.webp';
        case 'platinum': return '/img/kingboxplus/platinum_mini.webp';
      }
      return '/img/kingboxplus/gold_mini.webp';
    }
  },
  methods: {
    resolveImage(path) {
      try {
        return require.context(
            "../../../../assets/img/kingboxplus",
            false,
            /\.(png|jpe?g|svg)$/
        )(`./${path.split("/").pop()}`);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    },
  }
}
</script>

<template>
  <a class="swiper-slide kb-victories-item">
    <img
      class="swiper-slide kb-victories-item-img"
      :src="resolveImage(victory.image)" />
    <div class="kb-victories-item-user">
      <img :src="badge" />
      <div
        class="kb-victories-item-user-name"
        :title="victory.user">
        {{ victory.user }}
      </div>
    </div>
    <div class="kb-victories-item-value" :title="`${victory.value}US$`">
      {{ valueShort }}
    </div>
  </a>
</template>

<style lang="scss" scoped>
.kb-victories-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 7px;
  text-decoration: none;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    opacity: .7;
  }

  &-img {
    width: 100%;
    border-radius: var(--radius);
  }

  &-user {
    display: flex;
    align-items: center;
    gap: .3rem;
    font-size: 0.75rem;
    color: white;

    img {
      width: 0.875rem;
      height: 0.875rem;
    }

    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-value {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 0.8125rem;
    text-align: center;
  }
}
</style>