import { render, staticRenderFns } from "./ApostalaGame.vue?vue&type=template&id=1a37ee5a&scoped=true"
import script from "./ApostalaGame.vue?vue&type=script&lang=js"
export * from "./ApostalaGame.vue?vue&type=script&lang=js"
import style0 from "./ApostalaGame.vue?vue&type=style&index=0&id=1a37ee5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a37ee5a",
  null
  
)

export default component.exports