<script>
export default {
  name: 'ApostalaRegister',
  props: ["config"],
  computed: {
    buttonConfig() {
      return `
        color: ${this.config.color} !important;
        background-color: ${this.config.background_color} !important;
        border: none !important;
      `
    },
  }
};
</script>


<template>
    <div class="apostala-register">
      <b-button size="sm" :style="buttonConfig">
        Signup
      </b-button>
    </div>
</template>

<style scoped lang="scss">
.apostala-register {
  button {
    .icon {
      transition: all 0.2s ease;
    }
    &:hover {
      filter: brightness(0.9);
      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}

#apostala-register-modal {
  a {
    color: var(--primary);
  }

  button {
    background-color: var(--primary) !important;
    color: var(--header-c) !important;
    border: none !important;

    &:hover {
      filter: brightness(0.9);
    }
  }
}
</style>