<template>
  <!-- eslint-disable -->

  <div
    class="flex flex-row min-h-screen w-100 bg-gray-100 text-gray-800 hero-image relative"
    :style="{
      width: '100vw',
      backgroundImage: `url(${this.lobby.header_section.third_img})`,
      backgroundColor: lobby.background_color,
    }"
  >
    <!--Footer Image-->
    <img
      class="absolute bottom-0 footer_image"
      alt=""
      :src="this.lobby.footer_section.primary_img"
      width="100px"
      height="100px"
    />

    <!--Footer Image-->
    <div class="absolute w-100 h-52 bottom-0 right-0 left-0 bg-black">
      <img
        class=""
        style="object-fit: cover; height: 100%; width: 100%"
        :src="this.lobby.footer_section.primary_img"
        alt="main_image"
      />
    </div>
    <!--Footer Image-->

    <!--Left Panel-->
    <aside
      @click.stop
      id="navigation-menu"
      :class="[showSideBar ? 'show' : '']"
      class="sidebar md:shadow transform md:translate-x-50 transition-transform duration-150 ease-in"
      :style="{ backgroundColor: lobby.background_color }"
    >
      <div class="sidebar-content px-2 py-2">
        <!--List of options-->
        <ul class="flex flex-col w-full pa-0 gap-1">
          <SidebarActivator
            @showHide="onClickMenu()"
            :menu-options="this.lobby.main_menu.menu_options"
            :color-config="{
              bgColor: this.lobby.main_menu.background_color,
              primaryTextColor: this.lobby.main_menu.primary_text_color,
              secondaryTextColor: this.lobby.main_menu.secondary_text_color,
            }"
          />

          <SidebarMenu
            menu-title="Categories"
            :color-config="{
              bgColor: this.lobby.tags_menu.background_color,
              primaryTextColor: this.lobby.tags_menu.primary_text_color,
              secondaryTextColor: this.lobby.tags_menu.secondary_text_color,
            }"
            :loading="false"
            type="tag"
          />

          <SidebarMenu
            menu-title="Favorites"
            :color-config="{
              bgColor: this.lobby.fav_menu.background_color,
              primaryTextColor: this.lobby.fav_menu.primary_text_color,
              secondaryTextColor: this.lobby.fav_menu.secondary_text_color,
            }"
            :loading="false"
            type="fav"
          />

          <SidebarMenu
            menu-title="Suppliers"
            :color-config="{
              bgColor: this.lobby.brands_menu.background_color,
              primaryTextColor: this.lobby.brands_menu.primary_text_color,
              secondaryTextColor: this.lobby.brands_menu.secondary_text_color,
            }"
            :loading="false"
            type="brand"
          />
        </ul>
        <!--List of options-->
      </div>
    </aside>
    <!--Left Panel-->

    <main
      class="main flex flex-col flex-grow transition-all duration-150 ease-in"
      style="width: 200px; z-index: 2"
    >
      <div
        class="call-to-action-wrapper relative overflow-hidden rounded-b-lg w-100 bg-blue-900"
        :style="{
          backgroundImage: `url(${this.lobby.header_section.primary_img})`,
        }"
      >
        <div class="h-full w-full overflow-hidden bg-fixed">
          <img
            :src="this.lobby.header_section.secondary_img"
            class="d-none d-sm-block absolute object-contain sm:flex w-1/3 h-1/3 top-1/4 right-20 lg:top-16 lg:h-72"
            alt=""
          />

          <div class="px-3">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <div
                  :style="showSideBar ? 'visibility: hidden' : ''"
                  class="hamburger-menu"
                >
                  <img
                    @click="onClickMenu()"
                    alt="icon"
                    style="height: 20px; width: 20px; cursor: pointer"
                    :src="hamburgerMenu"
                  />
                </div>

                <button
                  class="text-white text-capitalize px-2"
                  style="font-size: 20px"
                  @click="showingInMenu.element = 'brandsPreview'"
                >
                  Home
                </button>
              </div>
            </nav>
          </div>

          <div class="flex flex-col mx-10 gap-10">
            <!--col-1-->
            <div class="row mt-8 align-items-center">
              <div class="col-md-7 col-sm-12 col-lg-6 col-xl-5">
                <call-to-action-words
                  :words="this.lobby.header_section.header_title.text"
                  :highlighted-word="'slots'"
                  :color-config="{
                    primaryTextColor:
                      this.lobby.header_section.header_title.primary_text_color,
                    secondaryTextColor:
                      this.lobby.header_section.header_title
                        .secondary_text_color,
                  }"
                />
                <button
                  :class="[
                    this.lobby.header_section.button_class,
                    'text-white px-2 py-1 rounded-md font--extra-bold shadow-lg mt-5',
                  ]"
                  :style="this.lobby.header_section.button_style"
                >
                  Register
                </button>
              </div>
            </div>

            <!--col-2-->
            <LobbyFeatured
              :color-config="{
                backgroundColor: this.lobby.background_color,
                shadowStyle: this.lobby.featured_section.shadow_style,
                primaryTextColor:
                  this.lobby.featured_section.primary_text_color,
              }"
            />
            <!--col-2-->

            <!--col-3-->
            <LobbyBestBrands
            />
            <!--col-3-->
          </div>
        </div>
      </div>

      <div class="main-content flex flex-col flex-grow p-4 text-white">
        <LobbyToolbar
          v-if="showingInMenu.element === 'game'"
          :toolbar-title="'all games'"
          :tabs-options="this.lobby.toolbar_menu.menu_options"
          :color-config="{
            bgColor: this.lobby.toolbar_menu.background_color,
            primaryTextColor: this.lobby.toolbar_menu.primary_text_color,
            secondaryTextColor: this.lobby.toolbar_menu.secondary_text_color,
          }"
        />

        <BrandGamesPreview
          v-if="showingInMenu.element === 'brandsPreview'"
          @clicked="brandPreviewClicked()"
          :config-color="{
            buttonClass: this.lobby.brands_games_preview.button_class,
            buttonStyle: this.lobby.brands_games_preview.button_style,
            bgColor: this.lobby.brands_games_preview.bg_color,
            borderColor: this.lobby.brands_games_preview.border_color,
          }"
          :loading="false"
        />

        <LobbyGames
          v-else-if="showingInMenu.element === 'game'"
          :color-config="{
            buttonClass: this.lobby.brands_games_preview.button_class,
            buttonStyle: this.lobby.brands_games_preview.button_style,
            bgColor: this.lobby.brands_games_preview.bg_color,
            borderColor: this.lobby.brands_games_preview.border_color,
          }"
        />

        <LobbyCard
          :color-config="{
            bgColor: this.lobby.featured_card.background_color,
            primaryTextColor: this.lobby.featured_card.primary_text_color,
            secondaryTextColor: this.lobby.featured_card.secondary_text_color,
          }"
          :card-title="this.lobby.featured_card.title || ''"
          :img="this.lobby.featured_card.img"
        />

        <LobbyRandomGames
          :color-config="{
            backgroundColor: this.lobby.background_color,
          }"
        />
      </div>

      <LobbyFooter />

      <div v-if="showSideBar" class="overlay" />
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import LobbyGames from "./components/LobbyGames.vue";
import CallToActionWords from "./components/CallToActionWords.vue";
import LobbyRandomGames from "./components/LobbyRandomGames.vue";
import LobbyFeatured from "./components/LobbyFeatured.vue";
import LobbyFooter from "./components/LobbyFooter.vue";
import LobbyToolbar from "./components/LobbyToolbar.vue";
import LobbyCard from "./components/LobbyCard.vue";
import BrandGamesPreview from "./components/BrandGamesPreview.vue";
import SidebarActivator from "./components/SidebarActivator.vue";
import SidebarMenu from "./components/SidebarMenu.vue";
import LobbyBestBrands from "./components/LobbyBestBrands.vue";
import menuIcon from "./assets/svg/menu.svg";

export default {
  name: "newLobby",
  props: ["lobby"],
  components: {
    LobbyGames,
    CallToActionWords,
    LobbyRandomGames,
    LobbyBestBrands,
    LobbyFeatured,
    LobbyFooter,
    LobbyToolbar,
    LobbyCard,
    BrandGamesPreview,
    SidebarActivator,
    SidebarMenu,
  },

  data() {
    return {
      // lobby: {
      //   background_color: "#174515",
      //   status: false,
      //   brands_games_preview: {
      //     primary_text_color: "#ffffff",
      //     secondary_text_color: "#ffffff",
      //     button_class:
      //       "bg-gradient-to-b from-green-400 via-green-700 to-blue-400",
      //     button_style: "",
      //     bg_color: "#0a291b",
      //     border_color: "#11a383",
      //   },
      //   brands_menu: {
      //     background_color: "#00351d",
      //     primary_text_color: "#00ff51",
      //     secondary_text_color: "#ffffff",
      //   },
      //   fav_menu: {
      //     background_color: "#0e802e",
      //     primary_text_color: "#ffffff",
      //     secondary_text_color: "#ffffff",
      //   },
      //   featured_card: {
      //     background_color: "#003522",
      //     primary_text_color: "#ffffff",
      //     secondary_text_color: "#ffffff",
      //     img: "img/newLobby/img_10.png",
      //     title: "Templo",
      //   },
      //   featured_section: {
      //     shadow_style: "0 2px 2px 0 #02ec92, 0 10px 10px 0 #11c56e",
      //     primary_text_color: "#ffffff",
      //   },
      //   footer_section: {
      //     primary_img:
      //       "https://cdnbc.s3.amazonaws.com/11/632887bb2f2e14ac4aed3ccf/lobby/844d8244-2d43-4e5b-b26f-6a175d0075da.png",
      //   },
      //   header_section: {
      //     primary_img:
      //       "https://cdnbc.s3.amazonaws.com/11/632887bb2f2e14ac4aed3ccf/lobby/0a283075-0de2-4776-b0eb-4463a8dc06e0.png",
      //     secondary_img: "img/newLobby/img.png",
      //     third_img:
      //       "https://cdnbc.s3.amazonaws.com/11/632887bb2f2e14ac4aed3ccf/lobby/1e38a887-d8a3-4420-bef8-c9d7defc58b7.png",
      //     header_title: {
      //       text: "AMAZING SLOTS GAMES",
      //       font_size: "24px",
      //       primary_text_color: "#00ff51",
      //       secondary_text_color: "#05d4d4",
      //     },
      //     button_class:
      //       "bg-gradient-to-b from-green-400 via-green-700 to-blue-400",
      //     button_style: "",
      //   },
      //   main_menu: {
      //     menu_options: [
      //       {
      //         title: "main menu",
      //         path: "/",
      //         icon: "mdi-menu",
      //       },
      //     ],
      //     background_color: "#05d49d",
      //     primary_text_color: "#ffffff",
      //     secondary_text_color: "#dadada",
      //   },
      //   tags_menu: {
      //     background_color: "#17b356",
      //     primary_text_color: "#ffffff",
      //     secondary_text_color: "#ffffff",
      //   },
      //   toolbar_menu: {
      //     background_color: "#00351d",
      //     primary_text_color: "#00ff88",
      //     secondary_text_color: "#ffffff",
      //   },
      //   softDelete: null,
      //   name: "CASINO",
      //   tags: [
      //     {
      //       _id: "6480d791a3cca22f2005d604",
      //       name: "JUEGOS CRASH",
      //       games: [],
      //       createdAt: "2023-06-07T19:16:33.109Z",
      //       updatedAt: "2023-06-07T19:16:33.109Z",
      //     },
      //   ],
      // },
      showSideBar: false,
      showingInMenu: { element: "brandsPreview" },
      hamburgerMenu: menuIcon,
    };
  },

  methods: {
    brandPreviewClicked() {
      this.showingInMenu.element = "game";
    },

    handleAllClicks(event) {
      this.closeSideBar();
      return event;
    },

    onClickMenu() {
      this.showSideBar = !this.showSideBar;
    },

    openSideBar() {
      this.showSideBar = true;
    },

    closeSideBar() {
      if (this.isSmallScreen()) {
        this.showSideBar = false;
      }
    },

    detectSmallScreen() {
      if (this.showSideBar) return;
      if (this.isSmallScreen()) {
        this.closeSideBar();
      } else {
        this.openSideBar();
      }
    },

    isSmallScreen() {
      return window.innerWidth < 640;
    },
  },
};
</script>

<style scoped>
.overlay {
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.hero-image {
  z-index: 1;
  /*background-image: url("/img/newLobby/img_2.png"); The image used */
  /*background-color: #000c26;  Used if the image is unavailable */
  /*↑↑↑ We have put this property where we use the class to make it dynamic ↑↑↑*/
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 100% 980px; /* Resize the background image to cover the entire container */
  /*height: 500px; !* You must set a specified height *!*/
}

.card-3d-effect {
  text-align: center;
}

.call-to-action-wrapper {
  /*background-image: url("/img/newLobby/img_1.png"); <-- We have put this property where we use the class to make it dynamic */
  background-size: cover;
  height: 100%;
  max-height: 700px !important;
}

aside.sidebar {
  left: 0;
  /*background: red; <-- We have put this property where we use the class to make it dynamic*/
  overflow-x: hidden;
  height: 100%;
  transform: translateX(-200px);
  position: absolute; /* Stay in place */
  width: 0;
  z-index: 3;
  transition: 0.2s ease-in; /* 0.5 second transition effect to slide in the sidebar */
  box-shadow: none;
}

aside.show {
  transform: translateX(0) !important;
  width: 200px !important;
}

/*@media screen(sm) {*/
/*    !* ... *!*/
/*}*/

/*sm*/
@media (min-width: 640px) {
  aside.sidebar {
    background-color: transparent !important;
    position: initial;
    transition: 0.1s ease-in;
  }

  aside.show {
    background-color: transparent !important;
  }

  .overlay {
    visibility: hidden;
  }
}

@media (max-width: 640px) {
  .hero-image,
  .hero-image::after {
    background-size: 100% 820px !important;
  }
}
</style>
