<script>
import { mapState } from "vuex";
import {computed} from "@vue/composition-api";
import store from "@/store";

export default {
  name: "ApostalaWhatsappButton",
  props: ["header"],
  setup() {
    const MODULE_WHITELABEL_STORE_NAME = "app-whitelabel-templates";

    const editTemplate = computed(
        () => store.state[MODULE_WHITELABEL_STORE_NAME].editTemplate
    );

    return {editTemplate}
  }
};
</script>

<template>
  <a
      class="circle-button"
      size="sm"
      :style="`background-color: ${
      header.auth.background_color || '#000'
    } !important`"
      :href="editTemplate.templateData.contact.whatsApp"
      target="_blank"
      rel="noopener noreferrer"
  >
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          :fill="header.auth.color ? header.auth.color : '#fff'"
          d="M4 1C2.34315 1 1 2.34315 1 4V15C1 16.6569 2.34315 18 4 18H6V22C6 22.388 6.22446 22.741 6.57584 22.9056C6.92723 23.0702 7.3421 23.0166 7.64018 22.7682L13.362 18H20C21.6569 18 23 16.6569 23 15V4C23 2.34315 21.6569 1 20 1H4Z"
      />
    </svg>
  </a>
</template>

<style scoped lang="scss">
@import "../assets/mixins.scss";

.circle-button {
  position: fixed;
  z-index: 100;
  right: 1rem;
  bottom: 1rem;

  border-radius: 100%;
  padding: 17px;
  border: none;
  display: grid;
  place-content: center;

  &:hover {
    filter: brightness(0.9);
  }

  @include responsive(lg) {
    position: static;
    right: auto;
    bottom: auto;
    padding: 7px;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    @include responsive(lg) {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
