<script>
import {computed} from "@vue/composition-api";
import store from "@/store";

export default {
  setup() {
    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    const tagsConfig = computed(() => editTemplate.value.templateData.tags);

    return {
      tagsConfig,
    };
  },
};
</script>

<template>
  <section class="bg-black" id="style-6">
    <div class="flex gap-16 mx-3 w-full swiper-wrapper">
      <div
          v-for="(item, index) in tagsConfig"
          :key="index"
          class="provider-title px-3"
      >
        <a class="p-0 text-decoration-none nav_link">
          <img alt="tag-image" :src="item.image"/>
          <span class="whitespace-nowrap" :style="{ color: 'white' }">{{
              item.title
            }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.provider-title img {
  height: 25px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.provider-title span {
  transition: all 0.2s ease;
}

.provider-title a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.provider-title a {
  font-size: 10px;
}

.provider-title:hover img {
  transform: scale(1.2);
  filter: invert(0.5) saturate(8) hue-rotate(10deg);
}

.provider-title:hover span {
  transform: translateY(1px) scale(0.9);
}

.swiper-wrapper {
  height: fit-content !important;
  padding: 20px !important;
  overflow-y: hidden !important;
}

</style>
