<script>
import ApostalaGamesRow from "@/views/components/whitelabel-templates/Apostala/components/modules/section/games/ApostalaGamesRow.vue";

export default {
  name: "ApostalaGamesList",
  props: {
    title: String,
  },
  components: {
    ApostalaGamesRow,
  },
  data() {
    return {
      groups: this.generateGroups(),
    };
  },
  methods: {
    generateGroups() {
      const defaultItem = {
        url_image:
          "https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3431c10a289178debf84cad55320277d2874bfa7/Games%20Catalog%20image/image.png",
        name: "Congo Cash Xl",
      };

      const alternativeItem = {
        url_image:
          "https://images.kbapi.net/images/Pragmaticplay/Congo%20Cash%20XL_472x400_1MB.gif",
        name: "Congo Cash Xl",
      };

      return [
        Array(8)
          .fill()
          .map(() => ({ ...defaultItem })),
        Array(8)
          .fill()
          .map(() => ({ ...defaultItem })),
        Array(8)
          .fill()
          .map(() => ({ ...defaultItem })),
      ];
    },
  },
};
</script>

<template>
  <div>
    <div class="apostala-best-games">
      <div class="apostala-best-games-title" v-if="title">
        <div class="apostala-best-games-title-text">{{ title }}</div>
      </div>

      <div class="apostala-best-games-list">
        <div v-for="(group, index) in groups" :key="index">
          <apostala-games-row :games="group" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.apostala {
  &-best-games {
    display: flex;
    flex-direction: column;
    background-color: #141414;

    &-title {
      display: flex;
      justify-content: center;
      color: white;
      text-transform: uppercase;
      padding: 10px 0 10px 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(calc(-50% + 5px));
        width: 100%;
        height: 4px;
        background: linear-gradient(
          90deg,
          transparent 0%,
          #fff 50%,
          transparent 100%
        );
        animation: pulse 3s ease-in-out infinite;
        margin-bottom: 10px;
      }

      @keyframes pulse {
        0%,
        100% {
          opacity: 0.4;
        }
        50% {
          opacity: 1;
        }
      }

      &-text {
        background-color: #141414;
        padding: 0 10px;
        z-index: 1;
        font-size: 18px;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 5px 20px 5px;
    }
  }
}
</style>
