<script>
import {ref} from "@vue/composition-api";

export default {
  props: {
    footer: {
      type: Object,
    },
  },

  data() {
    return {};
  },

  setup() {

  },

  computed: {
    footerStyle() {
      return {
        backgroundImage: this.footer.background_image ? `url('${this.footer.background_image}')` : "",
        backgroundColor: this.footer.background_color || "#000",
        color: this.footer.color || "#fff",
      };
    },
  },

  methods: {},
  mounted() {
  }
};
</script>


<template>
  <div :style="footerStyle" v-if="footer" class="apostala-footer">

    <div class="apostala-footer-left">
      <div class="navbar-brand">
        <a href="">
          <img :src="footer.left.image" class="pure-img" style="width: 250px; max-width: 100%;" alt="">
        </a>
      </div>
      <p v-for="(i, index) in footer.left.items" :key="index">{{ i.content }}</p>
    </div>

    <div class="apostala-footer-right">
      <div v-for="(section, index) in footer.right.sections" :key="index" class="apostala-footer-section">
        <div class="apostala-footer-section-title">{{ section.title }}</div>
        <a v-for="(i, index) in section.items" :key="index" class="apostala-footer-section-item">
          {{ i.content }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.apostala-footer {
  background-color: #053d76;
  color: var(--text-color);
  padding: 80px 0 180px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;

    p {
      margin: 0;
    }
  }

  &-right {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title {
      font-weight: 700;
      font-size: 22px;
    }

    &-item {
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      color: var(--text-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
