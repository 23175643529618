<template>
  <section class="text-constrain">
    <p
      :style="textStyle"
      v-html="innerTex"
      ref="text"
      class="text-white text-uppercase lh-sm font-weight-bold call-to-action-text leading-none"
    ></p>
  </section>
</template>

<script>
export default {
  name: "CallToActionWords",
  data() {
    return {
      innerTex: "",
    };
  },
  props: {
    highlightedWord: {
      type: String,
      default() {
        return "Slots";
      },
    },
    words: {
      type: String,
      default() {
        return "amazing slots games";
      },
    },
    colorConfig: {
      type: Object,
      default() {
        return {
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#d40571",
        };
      },
    },
  },

  computed: {
    textStyle() {
      return {
        color: this.colorConfig.primaryColor,
      };
    },
  },

  created() {},

  methods: {
    wordsStyle(words, word) {
      return {
        color: this.middleWord(words) === word ? "red" : "white",
      };
    },
    wordsClass(words, word) {
      return {
        "call-to-action-text-middle": this.middleWord(words) === word,
      };
    },

    async textFormat() {
      const tText = this.words;
      let text = tText.toLowerCase();
      const color = this.colorConfig.secondaryTextColor;
      const word = this.getLastWord(tText);

      if (word) {
        text = text.replace(
          word.toLowerCase(),
          `<span style="color: ${color}">${word}</span>`
        );
      }
      this.innerTex = text.toUpperCase();
    },

    middleWord(words) {
      const array = words.trim().split(" ");
      //const array = new Array(...words);
      return array[Math.ceil(array.length / 2) - 1];
    },

    getLastWord(words) {
      const wordsArray = words.split(" ");
      return wordsArray[wordsArray.length - 1];
    },
  },

  mounted() {
    this.textFormat();
  },
};
</script>

<style scoped>
.call-to-action-text {
  /*font-size: 40px;*/
  /*background-color: #774c0d;*/
}

/*sm*/
@media (min-width: 640px) {
  .call-to-action-text {
    /*font-size: 55px;*/
    /*background-color: #0a0a29;*/
  }
}

/*md*/
@media (min-width: 780px) {
  .call-to-action-text {
    /*font-size: 45px;*/
    /*background-color: #03e8f3;*/
  }
}

/*lg*/
@media (min-width: 1024px) {
  .call-to-action-text {
    /*font-size: 55px;*/
    /*background-color: #8f2a1f;*/
  }
}

/*xl*/
@media (min-width: 1280px) {
  .call-to-action-text {
    /*font-size: 55px;*/
    /*background-color: #411f9f;*/
  }
}

/*2xl*/
@media (min-width: 1536px) {
  .call-to-action-text {
    /*font-size: 55px;*/
    /*background-color: #ffffff;*/
  }
}

@media (max-width: 380px) {
  .call-to-action-text-middle {
    /*margin-top: 20px;*/
    display: block;
  }
}

.text-constrain {
  max-width: 400px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 40px;
  display: -webkit-box;
  display: -moz-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #ffff;
  overflow-wrap: break-word;
}
</style>
