<script>
export default {
  name: "GameInfo",
  data() {
    return {
      show: false
    }
  }
}
</script>

<template>
  <div class="kb-game-info" @mouseenter="show = true" @mouseleave="show = false"  :class="{ 'show': show }">
    <div class="kb-game-info-content bg-dark">
      <div class="kb-game-info-content-rtp">
        RTP: <span>97%</span>
      </div>
      <div class="kb-game-info-content-profits">
        Mayor ganancias reciente: <span>5000.30X</span>
      </div>
      <div class="kb-game-info-content-footer">
        PG Soft
      </div>
    </div>
    <svg class="kb-game-info-icon" viewBox="0 0 32 32">
      <path
        d="M12.926 30c-6.034 0-10.925-4.892-10.925-10.925v-6.149c0-6.034 4.892-10.925 10.925-10.925h6.149c6.033 0 10.925 4.892 10.925 10.925v6.149c0 6.034-4.892 10.925-10.925 10.925h-6.149zM12.926 27.62h6.149c4.721 0 8.546-3.827 8.546-8.546v-6.149c0-4.721-3.827-8.546-8.546-8.546h-6.149c-4.721 0-8.546 3.827-8.546 8.546v6.149c0 4.721 3.827 8.546 8.546 8.546zM16.001 12.841c-0.713 0-1.303-0.53-1.396-1.217l-0.012-0.191v-0.090c0-0.778 0.631-1.409 1.409-1.409 0.713 0 1.303 0.53 1.396 1.217l0.012 0.191v0.090c0 0.778-0.631 1.409-1.409 1.409zM16.001 22.874c-0.713 0-1.303-0.53-1.396-1.217l-0.012-0.191v-6.047c0-0.778 0.631-1.409 1.409-1.409 0.713 0 1.303 0.53 1.396 1.217l0.012 0.191v6.047c0 0.779-0.631 1.409-1.409 1.409z">
      </path>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.kb-game-info {  
  &.show {
    .kb-game-info-content {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translate(-50%, 10px);
    width: 300px;
    box-shadow: 0 0 15px color-mix(in srgb, var(--shadow-color) 70%, transparent 100%);
    padding: 10px;
    z-index: 1;
    border-radius: var(--radius);
    opacity: 0;
    pointer-events: none;
    transition: var(--transition-slow);
    color: var(--sub-text-color);

    &-rtp,
    &-profits {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--standard-color);
      padding: 5px;
      border-radius: var(--radius);
    }

    &-rtp {
      span {
        color: var(--green-color);
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--placeholder-color)
  }
}  
</style>