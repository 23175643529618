<script>
export default {
  name: "ApostalaCarousel",
  props: ["carousel"],
};
</script>

<template>
  <b-carousel :interval="2000" controls>
    <b-carousel-slide
      v-for="(slide, index) in carousel.items"
      :key="index"
      :img-src="slide.image"
    />
  </b-carousel>
</template>

<style scoped lang="scss"></style>
