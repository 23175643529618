<template>
  <li class="my-px">
    <div
      v-for="(item, index) in menuOptions"
      :key="index"
      href="#"
      class="flex flex-row w-full items-center justify-evenly h-10 px-1 ma-0 rounded-lg text-white py-6"
      :style="{ backgroundColor: colorConfig.bgColor }"
    >
      <img
        @click="onClick()"
        alt="icon"
        style="height: 20px; width: 20px; cursor: pointer"
        :src="hamburgerMenu"
      />

      <span class="font--bold w-2/3 text-sm">
        {{ item.title.toUpperCase() }}
      </span>
    </div>
  </li>
</template>

<script>
import menuIcon from "../assets/svg/menu.svg";
export default {
  name: "SidebarActivator",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          bgColor: "#d40571",
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#dadada",
        };
      },
    },

    menuOptions: {
      type: Array,
      required: false,
      default() {
        return [{ title: "Main menu", path: "/", icon: "mdi-menu" }];
      },
    },
  },

  data() {
    return {
      hamburgerMenu: menuIcon,
    };
  },

  computed: {},

  methods: {
    onClick() {
      this.$emit("showHide");
    },
  },
};
</script>

<style scoped></style>
