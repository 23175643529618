<template>
  <section class="mt-4" ref="lobbyGamesWrapper">
    <header
      class="flex flex-wrap flex-row justify-between items-center mx-4 text-white"
    >
      <section class="flex flex-row items-center gap-4 mr-5">
        <div class="font-weight-bold" :style="{ color: 'white' }">Brand</div>
      </section>
      <input
        id="searching"
        class="mb-1 px-2 py-2 mt-3 rounded text-white w-1/3 text-white"
        :style="{ backgroundColor: colorConfig.bgColor }"
        placeholder="Search"
      />
    </header>

    <div>
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-6 mt-4 gap-3"
      >
        <div
          v-for="(item, index) in 20"
          :key="index"
          class="rounded-l-lg rounded-tr-3xl rounded-br-lg"
          :style="{
            background: colorConfig.bgColor,
            borderColor: colorConfig.borderColor,
          }"
          style="border-width: 3px; max-width: 100%; overflow: hidden"
        >
          <div
            class="flex flex-row items-end w-100 gap-1 relative"
            style="height: 150px"
          >
            <!--            <img-->
            <!--              alt="Game Image"-->
            <!--              :style="{ color: colorConfig.bgColor }"-->
            <!--              class="object-cover bg-no-repeat bg-contain h-full w-full transform hover:scale-105 transition duration-500"-->
            <!--              :src="item.game.url_image"-->
            <!--            />-->

            <div class="absolute flex flex-row gap-1 items-center p-1">
              <section class="rounded-full bg-black flex-none">
                <!--                <img-->
                <!--                  class="rounded-full object-contain"-->
                <!--                  alt=""-->
                <!--                  style="height: 25px; width: 25px"-->
                <!--                  :src="item.game.brandId.url_image || 'img/newLobby/img_9.png'"-->
                <!--                />-->
              </section>
              <section class="flex flex-col overflow-hidden">
                <span class="leading-3 text-sm truncate pt-1"> Game</span>
                <span style="font-size: 10px" class="font--light truncate">
                  Brand</span
                >
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*eslint-disable*/

export default {
  name: "LobbyGames",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          bgColor: "",
          primaryTextColor: "",
          secondaryTextColor: "",
        };
      },
    },
  },
};
</script>

<style scoped></style>
