<script>
import {BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback} from "bootstrap-vue";
import {ValidationProvider} from "vee-validate";

export default {
  name: "authContent",
  components: {BFormInput, BFormCheckbox, BFormInvalidFeedback, ValidationProvider, BFormGroup},

  props: {
    auth: {
      default: {
        background_color: '',
        color: '',
        hide: false
      }
    }
  },

  setup(props, {emit}) {
    const onColorChange = (value, key) => {
      emit('change', {
        key,
        value,
      })
    }

    return {
      onColorChange
    }
  },


}
</script>

<template>
  <div>
    <hr>
    <h5>Authentication</h5>
    <div class="row px-1 mt-2">
      <b-form-group class="w-100" label-for="background_color">
        <template #label>{{ $t("labels.backgroundcolor") }}</template>
        <b-input
            @input="onColorChange($event, 'auth.background_color')"
            type="color"
            id="background_color"
            v-model="auth.background_color"
            label="background_color"
        />
      </b-form-group>

      <b-form-group class="w-100" label-for="color">
        <template #label>{{ $t("labels.color") }}</template>
        <b-input
            type="color"
            id="color"
            @input="onColorChange($event, 'auth.color')"
            v-model="auth.color"
            label="color"
        />
      </b-form-group>

    </div>
  </div>
</template>

<style scoped lang="scss">

</style>