<template>
  <div>
    <!-- Lógica común, modales, etc. -->
    <b-card class="pb-5" no-body>
      <div class="px-1 mt-1">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <b-button @click="goBack" variant="light" size="sm">
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </b-button>
            <div class="font-weight-bold font-medium-2 ml-1">
              {{ $t("lobby") }}
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="mr-1 font-small-3">
              {{ unfolded ? $t("caollapse_all") : $t("display_all") }}
            </div>
            <b-form-checkbox
              @change="setUnfolded(!unfolded)"
              v-model="unfolded"
              class="mr-0"
              switch
            />
          </div>
        </div>

        <div class="px-1 mt-1">
          <validation-observer ref="lobbyForm">
            <b-form v-if="data">
              <!-- Sección main -->
              <div :class="collapse.main ? '' : 'border-bottom border-gray'"
              >
                <div
                    @click="collapse.main = !collapse.main"
                    class="media py-2"
                    :aria-expanded="collapse.main ? 'true' : 'false'"
                    aria-controls="collapse-main"
                >
                  <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
                    <feather-icon size="18" icon="SquareIcon" />
                  </div>

                  <div
                      class="d-flex justify-content-between w-100"
                      style="padding-top: 10px"
                  >
                    <h4>
                      {{ $t("main_component") }}
                    </h4>

                    <div>
                      <feather-icon
                          size="20"
                          :icon="collapse.main ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                      />
                    </div>
                  </div>
                </div>

                <b-collapse id="collapse-main" v-model="collapse.main">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="px-2 row">
                        <!-- Field: lobby Name -->
                        <b-form-group
                            class="w-100 col-lg-6"
                            label-for="name"
                            :state="Boolean(data.name)"
                        >
                          <h5>{{ $t("labels.name") }}</h5>
                          <validation-provider
                              #default="{ errors }"
                              name="name"
                              vid="name"
                              rules="required"
                          >
                            <b-form-input
                                id="name"
                                aria-describedby="input-live-help input-live-feedback"
                                v-model="data.name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <div class="px-2 row">
                        <!-- Field: lobby Name -->
                        <b-form-group class="w-100 col-lg-6" label-for="backgroundcolor">
                          <h5>{{ $t("labels.backgroundcolor") }}</h5>
                          <b-form-input
                              type="color"
                              id="backgroundcolor"
                              @change="onColorChange($event, 'background_color')"
                              :value="data.background_color"
                              trim
                          />
                        </b-form-group>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <div class="px-2 row">
                        <!-- Field: lobby Name -->
                        <b-form-group class="col-lg-6 w-100">
                          <h5>{{ $t("labels.status") }}</h5>

                          <b-form-checkbox
                              name="check-button"
                              v-model="data.status"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-collapse>
              </div>


              <!-- Sección de tags -->
              <div
                :class="collapse.lobbyTags ? '' : 'border-bottom border-gray'"
              >
                <div
                  @click="collapse.lobbyTags = !collapse.lobbyTags"
                  class="media py-2"
                  :aria-expanded="collapse.lobbyTags ? 'true' : 'false'"
                  aria-controls="collapse-tags"
                >
                  <div
                    class="mr-1 text-white bg-primary rounded"
                    style="padding: 8px"
                  >
                    <feather-icon size="20" icon="ListIcon" />
                  </div>

                  <div
                    class="d-flex justify-content-between w-100"
                    style="padding-top: 10px"
                  >
                    <h5>
                      {{ $t("tags_section") }}
                    </h5>

                    <div>
                      <feather-icon
                        size="20"
                        :icon="
                          collapse.products
                            ? 'ChevronUpIcon'
                            : 'ChevronDownIcon'
                        "
                      />
                    </div>
                  </div>
                </div>

                <b-collapse id="collapse-products" v-model="collapse.lobbyTags">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="px-2 row">
                        <!-- Field: lobby Name -->
                        <b-form-group class="col-lg-6 w-100">
                          <h5>{{ $t("labels.tags") }}</h5>
                          <validation-provider
                            #default="{ errors }"
                            name="tags"
                            vid="tags"
                            rules="required"
                          >
                            <multiselect
                              class="multiselect"
                              id="tags"
                              :options="tagsOptions"
                              :value="data.tags"
                              :close-on-select="false"
                              :multiple="true"
                              :placeholder="$t('search_filter')"
                              track-by="_id"
                              label="name"
                              @select="addTag"
                              @remove="removeTag"
                            >
                              <span slot="noResult">
                                {{ $t("no_result") }}
                                .</span
                              >
                            </multiselect>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </b-list-group-item>

                    <b-list-group-item>
                      <h5 class="px-2 mb-2">{{ $t("labels.display_name") }}</h5>
                      <div class="px-2 row">
                        <b-form-group
                          v-for="(tag, index) in data.tags"
                          :key="index"
                          class="col-lg-6 w-100"
                        >
                          <h5>{{ tag.name }}</h5>
                          <b-form-input v-model="tag.title" type="text" trim />
                          <image-field-form
                              :single-button="true"
                              :text="$t('labels.image')"
                              class="w-100 mt-1"
                              :value="tag.image"
                              path="lobby"
                              @uploadedImage="onUploadedImage($event, 'image', index)"
                              @error="errorInLoadImage($event)"
                              preview="true"
                          >
                          </image-field-form>
                          
                          <b-img v-if="tag.image" :src="tag.image" width="80" height="80" class="p-1"/>

                          <b-form-checkbox
                            class="mt-1"
                            v-model="tag.isFeaturedTag"
                          >
                            Tag de Destacado
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </b-list-group-item>

                  </b-list-group>
                </b-collapse>
              </div>

              <!-- Sección de productos -->
              <div
                :class="collapse.products ? '' : 'border-bottom border-gray'"
              >
                <div
                  @click="collapse.products = !collapse.products"
                  class="media py-2"
                  :aria-expanded="collapse.products ? 'true' : 'false'"
                  aria-controls="collapse-tags"
                >
                  <div
                    class="mr-1 text-white bg-primary rounded"
                    style="padding: 8px"
                  >
                    <feather-icon size="20" icon="ListIcon" />
                  </div>

                  <div
                    class="d-flex justify-content-between w-100"
                    style="padding-top: 10px"
                  >
                    <h5>
                      {{ $t("products_section") }}
                    </h5>

                    <div>
                      <feather-icon
                        size="20"
                        :icon="
                          collapse.products
                            ? 'ChevronUpIcon'
                            : 'ChevronDownIcon'
                        "
                      />
                    </div>
                  </div>
                </div>

                <b-collapse id="collapse-products" v-model="collapse.products">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="px-2 row">
                        <b-form-group class="col-lg-6 w-100">
                          <h5>{{ $t("labels.products") }}</h5>

                          <validation-provider
                            #default="{ errors }"
                            name="products"
                            vid="products"
                            rules="required"
                          >
                            <v-select
                              style="margin-right: 10px"
                              class="w-100"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              label="productId"
                              :options="productsOptions"
                              v-model="selectedProducts"
                              @input="onChangeSelectedProduct()"
                              multiple
                              :close-on-select="false"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- Field: lobby Name -->
                        <b-form-group class="col-lg-6 w-100">
                          <h5>{{ $t("labels.brands") }}</h5>
                          <b-button @click="openBrandModal()">{{
                            $t("labels.brands")
                          }}</b-button>
                        </b-form-group>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-collapse>
              </div>

              <!-- Slot para el formulario específico del lobby -->
              <slot
                name="specific-form"
                :data="data"
                :collapse="collapse"
              ></slot>

              <!-- Botones de acción -->
              <b-button
                v-if="!isEdit"
                variant="primary"
                class="my-2 mb-sm-0 mr-0 mr-sm-1 float-right"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="cancelChange()"
              >
                {{ $t("buttons.cancel") }}
              </b-button>

              <b-button
                variant="primary"
                class="my-2 mb-sm-0 mr-0 mr-sm-1 float-right"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveChanges()"
              >
                {{ $t("buttons.save") }}
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-card>

    <!-- Modales -->
    <b-modal
      v-model="modal.show"
      no-close-on-esc
      no-close-on-backdrop
      size="sm"
      cancel-variant="outline-secondary"
      centered
      :title="modal.title"
      hide-footer
      hide-header-close
    >
      <b-card>
        <div
          class="d-flex flex-column flex-wrap align-items-center justify-content-center"
        >
          <div class="mb-1">{{ modal.subtitle }}</div>
          <b-spinner
            v-if="modal.loading"
            type="grow"
            label="Spinning"
            class="mr-1"
            small
          ></b-spinner>
        </div>
      </b-card>
    </b-modal>

    <b-modal
      v-model="productModal.show"
      no-close-on-esc
      no-close-on-backdrop
      size="sm"
      cancel-variant="outline-secondary"
      centered
      :title="$t('labels.brands')"
      hide-footer
      hide-header-close
    >
      <b-card no-body>
        <div class="d-flex flex-wrap flex-column">
          <div style="overflow-x: auto">
            <draggable
              style="height: 300px"
              v-model="brands"
              tag="ul"
              handle=".move-handle"
              @change="onChangeDrag"
              class="list-group"
              ghost-class="ghost"
              :animation="100"
            >
              <transition-group type="transition" name="flip-list">
                <b-list-group-item
                  tag="li"
                  v-for="(brand, index) in brands"
                  :key="brand.value"
                  class="d-flex align-items-center justify-content-around"
                >
                  <feather-icon
                    size="12"
                    icon="AlignJustifyIcon"
                    class="move-handle cursor-move"
                  />

                  <div class="mx-2 flex-grow-1 user-select-none">
                    {{ brand.value }}
                  </div>

                  <div class="d-flex align-items-center w-full">
                    <feather-icon
                      v-if="index === 0"
                      class="cursor-not-allowed"
                      style="color: black"
                      icon="ChevronsUpIcon"
                    />
                    <feather-icon
                      v-else
                      class="cursor-pointer"
                      style="color: cornflowerblue"
                      @click="
                        brands.splice(index - 1, 0, brands.splice(index, 1)[0])
                      "
                      icon="ChevronsUpIcon"
                    />

                    <div class="px-1">
                      <b-form-input
                        type="number"
                        size="sm"
                        disabled
                        style="width: 3rem"
                        :value="index + 1"
                      />
                    </div>

                    <div>
                      <b-form-checkbox
                        v-model="brands[index].selected"
                        class="mr-0"
                        switch
                        @change="dispatchValidation($event, index, true)"
                      />
                    </div>
                  </div>
                </b-list-group-item>
              </transition-group>
            </draggable>
          </div>

          <b-alert v-model="productModal.error" variant="danger" class="ma-3">
            <h5 class="alert-heading ma-2">
              {{ productModal.errorText }}
            </h5>
          </b-alert>
          <b-button @click="saveProductsConfig" class="mt-1">
            {{ $t("buttons.accept") }}
          </b-button>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import lobbyStoreModule from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/store/lobbyStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { set, sortBy, cloneDeep } from "lodash";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import { required } from "@core/utils/validations/validations";
import {BButton, BCol} from "bootstrap-vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";

export default {
  name: "LobbyCommonLogic",

  components: {
    BButton, ImageFieldForm, BCol,
    draggable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Multiselect,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      unfolded: true,

      data: {
        _id: "",
        name: "",
        background_color: "",
        status: false,
        tags: [],
        products: [],
      },

      collapse: {
        main: false,
        tags: false,
        lobbyTags: false,
        brands: false,
        mainMenu: false,
        featuredCard: false,
        products: false,
        favMenu: false,
        featuredSection: false,
        headerSection: false,
        toolbar: false,
        games: false,
        footer: false,
      },

      modal: {
        show: false,
        title: "",
        subtitle: "",
        loading: false,
      },

      productModal: {
        show: false,
        error: false,
        errorText: "Error",
      },

      brands: [],
      selectedProducts: [],
      productsOptions: [],
      tagsOptions: [],
      images: [],
    };
  },

  computed: {
    clientId() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel.client;
    },

    isEdit() {
      return !!this.$route.params.id;
    },
  },

  methods: {
    ...mapActions("templatesModule", ["getLobby"]),
    ...mapActions("product", ["fetchProducts"]),

    async getData() {
      try {
        const response = await this.getLobby(this.id, {});
        this.data = response.data;
        console.log(this.data)
        if (!Array.isArray(this.data.tags)) {
          this.data = { ...this.data, tags: [] };
        }
      } catch (error) {
        console.error("Error fetching lobby data:", error);
        this.showToast({
          title: this.$t("error_fetching_data"),
          variant: "danger",
        });
      }
    },

    async getProducts(whitelabelId) {
      try {
        const response = await store.dispatch(
          "app-lobby/fetchProductsByWhitelabel",
          whitelabelId
        );
        this.products = [...new Set(response.data)];
      } catch (error) {
        console.error("Error fetching products:", error);
        this.showToast({
          title: this.$t("error_fetching_products"),
          variant: "danger",
        });
      }
    },

    disinfectLobbyTags(lobby) {
      return {
        ...lobby,
        // tags: lobby.tags.map((tag) => tag.name),
      };
    },

    async getListTags(clientId) {
      try {
        const response = await store.dispatch(
          "app-lobby/fetchListTags",
          clientId
        );
        this.tagsOptions = response.data;
      } catch (error) {
        console.error("Error fetching tags:", error);
        this.showToast({
          title: this.$t("error_fetching_tags"),
          variant: "danger",
        });
      }
    },

    async getDefaultLobby(clientId) {
      const templateId = this.$route.query.templateId;
      const response = await store.dispatch(
          "app-lobby/getDefaultLobby",
          {templateId}
      );
      this.data = response.data[0];
      if (!Array.isArray(this.data.tags)) {
        this.data = { ...this.data, tags: [] };
      }
    },

    async loadLobbyImages() {
      try {
        const response = await this.$store.dispatch(
          "app-lobby/fetchLobbyImage",
          this.$store.state.whitelabelCurrencyNabvar.whitelabel.client
        );
        this.images = response.data;
      } catch (error) {
        console.error("Error loading lobby images:", error);
        if (error.response && error.response.status === 404) {
          this.images = [];
        }
      }
    },

    async saveChanges() {
      const isValid = await this.validateForm();
      if (!isValid) {
        this.showToast({
          title: this.$t("login.errorValidate"),
          variant: "danger",
        });
        return false;
      }

      if (this.isEdit) {
        await this.updateLobby();
      } else {
        await this.createLobby();
      }
    },

    async createLobby() {
      await this.setModal({
        show: true,
        title: "Lobby",
        sub: this.$t("saving_lobby"),
        loading: true,
      });

      try {
        delete this.data._id;
        delete this.data.updatedAt;
        this.data.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
        this.data.templateId = this.$route.query.templateId;
        this.data.type = this.$route.query.templateType

        await store.dispatch(
          "app-lobby/addLobby",
          this.disinfectLobbyTags(this.data)
        );

        this.showToast({
          title: this.$t("lobby_save"),
          variant: "success",
        });

        await this.setModal({
          loading: false,
          callback: () => (this.modal.show = false),
        });

        this.pushToTemplatePreview();
      } catch (error) {
        console.error("Error creating lobby:", error);
        this.showToast({
          title: this.$t("error_saving_lobby"),
          variant: "danger",
        });
        await this.setModal({
          loading: false,
          error: true,
          callback: () => (this.modal.show = false),
        });
      }
    },

    async updateLobby() {
      await this.setModal({
        show: true,
        title: "Lobby",
        sub: this.$t("modifying_lobby"),
        loading: true,
      });

      try {
        await store.dispatch("app-lobby/updateLobby", {
          id: this.data._id,
          lobbyData: this.data,
        });

        this.showToast({
          title: this.$t("lobby_updated"),
          variant: "success",
        });

        await this.setModal({
          loading: false,
          callback: () => (this.modal.show = false),
        });

        this.pushToTemplatePreview();
      } catch (error) {
        console.error("Error updating lobby:", error);
        this.showToast({
          title: this.$t("error_updating_lobby"),
          variant: "danger",
        });
        await this.setModal({
          loading: false,
          error: true,
          callback: () => (this.modal.show = false),
        });
      }
    },

    async validateForm() {
      try {
        return await this.$refs.lobbyForm.validate();
      } catch (err) {
        console.error("Validation error:", err);
        return false;
      }
    },

    showToast({ title, variant, text }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant === "success" ? "CheckCircleIcon" : "AlertTriangleIcon",
          variant,
          text,
        },
      });
    },

    setModal({ show, title, sub, loading, callback, error }) {
      if (loading) {
        this.modal.show = show;
        this.modal.title = title;
        this.modal.subtitle = sub;
        this.modal.loading = true;
        return Promise.resolve(true);
      } else {
        this.modal.loading = false;
        this.modal.subtitle = error
          ? this.$t("generic_error")
          : this.$t("filled");
        return new Promise((resolve) => {
          setTimeout(() => {
            callback();
            resolve();
          }, 500);
        });
      }
    },

    onChangeDrag(event) {
      console.log(event);
      //update the order position
      this.brands.forEach((b, index) => {
        b.order = index + 1;
      });
    },

    onChangeSelectedProduct() {
      const assemble = this.assembleProducts();
      console.log("assemble product result");
      console.log(assemble);
      this.manageBrands();
    },

    addTag(val) {
      val.title = val.name;
      val.isFeaturedTag = false;
      this.data.tags.push(val);
    },

    removeTag(val) {
      const index = this.data.tags.findIndex((tag) => tag._id === val._id);
      if (index !== -1) {
        this.data.tags.splice(index, 1);
      }
    },

    onChangeImage(value, target) {
      if (value === "reload" && this.images?.routes?.length) {
        this.loadLobbyImages();
      } else if (value === "error") {
        console.log("error loading image");
      } else {
        set(this.data, target, value);
      }
    },

    onUploadedImage(data, target, index = null) {
      if (!data?.files.length) {
        return
      }
      if (index !== null) {
        this.data.tags[index][target] = data.files[data.files.length - 1]
      } else {
        set(this.data, target, data.files[data.files.length - 1]);
      }
    },

    errorInLoadImage(error) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    onColorChange(event, target) {
      set(this.data, target, event);
    },

    manageBrands() {
      this.$nextTick(() => {
        const allBrands = this.getAllBrandsOfSelectedProducts();
        const lobbyBrands = this.getAllBrandsFromLobby();
        const matches = this.findMatchesAndSelect(
          cloneDeep(allBrands),
          cloneDeep(lobbyBrands)
        );
        const notRepeatedOrder = this.notRepeatOrder(cloneDeep(matches));

        this.brands = notRepeatedOrder.sort((a, b) => {
          if (a.order === -1 && b.order === -1) return 0;
          if (a.order === -1) return 1;
          if (b.order === -1) return -1;
          return a.order - b.order;
        });

        this.assembleProducts();
      });
    },

    getAllBrandsOfSelectedProducts() {
      const filtered = this.products.filter((p) =>
        this.selectedProducts.includes(p.productId)
      );
      const brandsMap = new Map();

      filtered.forEach((p) => {
        p.brandIds.forEach((b) => {
          const key = `${p.productId} - ${b.brandId}`;
          if (!brandsMap.has(key)) {
            brandsMap.set(key, {
              value: key,
              productId: p.productId,
              brandId: b.brandId,
            });
          }
        });
      });

      return [...brandsMap.values()];
    },

    saveProductsConfig() {
      // if (this.productModal.error) return;
      this.assembleProducts();
      // Hide the product modal
      this.productModal.show = false;
    },

    getAllBrandsFromLobby() {
      const brandsMap = new Map();

      this.data.products.forEach((p) => {
        p.brandIds.forEach((b) => {
          const key = `${p.productId} - ${b.brandId}`;
          if (!brandsMap.has(key)) {
            brandsMap.set(key, {
              value: key,
              productId: p.productId,
              brandId: b.brandId,
              order: b.order || -1,
            });
          }
        });
      });

      return [...brandsMap.values()];
    },

    findMatchesAndSelect(arr1, arr2) {
      return arr1.map((obj1) => {
        const match = arr2.find((obj2) => obj1.value === obj2.value);
        return match
          ? { ...obj1, selected: true, order: match.order || -1 }
          : { ...obj1, selected: false, order: -1 };
      });
    },

    //assistant methods to lobby
    setProductModalError({ show, title = "" }) {
      this.productModal.error = show;
      this.productModal.errorText = title;
    },

    sizeValidation({ ifErrorOpen }) {
      const selectedProducts = this.brands.filter((p) => p.selected === true);
      if (!selectedProducts.length) {
        this.productModal.error = true;
        this.productModal.errorText = this.$t(
          "messages.mustSelectAtLeastOneBrand"
        );
        ifErrorOpen === true ? (this.productModal.show = true) : "";
      }
      return selectedProducts.length > 0;
    },

    dispatchValidation(event, index, isSwitch = false) {
      this.setProductModalError({ show: false });
      this.sizeValidation({ isErrorOpen: false });
    },

    notRepeatOrder(updatedBrands) {
      const usedOrders = new Set(
        updatedBrands.filter((b) => b.selected).map((item) => item.order)
      );
      let nextOrder = 1;

      return updatedBrands.map((brand) => {
        if (brand.order === -1) {
          while (usedOrders.has(nextOrder)) {
            nextOrder++;
          }
          brand.order = nextOrder;
          usedOrders.add(nextOrder);
        }
        return brand;
      });
    },

    assembleProducts() {
      const accumulated = this.brands.reduce((acc, curr, index) => {
        if (curr.selected) {
          const existing = acc.find((b) => b.productId === curr.productId);
          const brand = { order: index + 1, brandId: curr.brandId };

          if (existing) {
            existing.brandIds.push(brand);
          } else {
            acc.push({
              productId: curr.productId,
              brandIds: [brand],
            });
          }
        }
        return acc;
      }, []);

      this.data.products = accumulated;
      return accumulated;
    },

    // Método para actualizar datos específicos del lobby
    updateSpecificData(specificData) {
      this.data = { ...this.data, ...specificData };
    },

    pushToTemplatePreview() {
      const templateId = this.$route.query.templateId;
      const templateType = this.$route.query.templateType;
      const query = templateId ? { id: templateId } : {};
      this.$router.push({ name: `${templateType}-template-preview`, query });
    },

    cancelChange() {
      this.pushToTemplatePreview();
    },

    //general view methods
    goBack() {
      this.pushToTemplatePreview();
    },

    setUnfolded(unfolded) {
      for (let prop in this.collapse) {
        this.collapse[prop] = unfolded;
      }
    },

    openBrandModal() {
      this.productModal.show = true;
      this.sizeValidation({ isErrorOpen: false });
    },
  },

  async mounted() {
    const WHITELABEL_APP_STORE_MODULE_NAME = "app-lobby";

    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) {
      store.registerModule(WHITELABEL_APP_STORE_MODULE_NAME, lobbyStoreModule);
    }

    try {
      await this.setModal({
        show: true,
        title: "Lobby",
        sub: this.$t("loading_data"),
        loading: true,
      });

      const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;

      if (this.isEdit) {
        await Promise.all([
          this.getData(),
          this.getProducts(whitelabelId),
          this.getListTags(clientId),
          this.loadLobbyImages(),
        ]);
      } else {
        await Promise.all([
          this.getDefaultLobby(clientId),
          this.getProducts(whitelabelId),
          this.getListTags(clientId),
          this.loadLobbyImages(),
        ]);
      }

      this.selectedProducts = this.data.products.map((p) => p.productId);
      this.productsOptions = this.products.map((p) => p.productId);

      this.manageBrands();

      await this.setModal({
        loading: false,
        callback: () => (this.modal.show = false),
      });
    } catch (e) {
      console.error("Error in mounted hook:", e);
      await this.setModal({
        loading: false,
        error: true,
        callback: () => (this.modal.show = false),
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
