<template>
   <div :style="{
      width: '10rem', 
      borderRadius: '8px',
      padding: '.5rem',
      backgroundColor: color
    }"
  > <slot></slot></div>  
</template>

<script>
export default {

  props:{
    color: String,
  }
}
</script>