<script>
import store from "@/store";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import InlineSvg from "@/views/components/whitelabel-templates/common/InlineSvg.vue";

export default {
  name: 'KBCategories',
  components: {InlineSvg},
  props: {
    categories: []
  },

  setup() {
    const MODULE_KB_STORE_NAME = "kb-store";
    // Register module
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule)
  },

  mounted() {
    this.sortCategories()
  },

  methods: {
    sortCategories() {
      const categories = this.categories
      categories.sort((a, b) => Number(a.position) - Number(b.position));
      this.categories = categories;
    },

    editItem(item) {
      store.commit(
          "kb-store/SET_CLICKED_CATEGORY", item
      );
    }
  }
}
</script>

<template>
  <div class="kb-category" v-if="categories">
    <a class="kb-category-item"
       :class="cat.variant"
       v-for="(cat, index) in categories" :key="index">
      <img
          @click="editItem(cat)"
          class="kb-category-item-img"
          :src="cat.image"
          alt=""
      />
      <div class="kb-category-item-content">
        <div class="kb-category-item-content-header">
          <inline-svg :svg-data="cat.svgData"
                      :class="'kb-category-svg-icon-'+cat.variant"/>
          <div class="kb-category-item-content-header-title">{{ cat.title }}</div>
        </div>
        <div class="kb-category-item-content-desc">
          {{ cat.text }}
        </div>
        <button class="btn btn-primary">
          <span class="button-inner">{{ cat.sub_title }}</span>
        </button>
      </div>
    </a>

  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.kb-category {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  margin-top: 30px;
  

  @include responsive(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 50px;
  }

  &-item {
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .kb-category-item-img {
        transform: scale(1.2);
      }

      .kb-category-item-content {
        transform: translateY(0);
      }
    }

    &.casino {
      background: #3c167a !important;

      &::after {
        background: #6216e9 !important;
      }

      .btn {
        background: #5c00ff !important;
      }
    }

    &.sports {
      background: #0b6227 !important;

      &::after {
        background: #079234 !important;
      }

      .btn {
        background: #00ff54 !important;
      }
    }

    &.racing {
      background: #834c0c !important;

      &::after {
        background: #c46b02 !important;
      }

      .btn {
        background: #ff8c00 !important;
      }
    }

    &.trading {
      background: #941616 !important;

      &::after {
        background: #f42525 !important;
      }

      .btn {
        background: #00ff52 !important;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      -ms-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
      z-index: 1;
      width: 109.54%;
      aspect-ratio: 356/196;
      border-radius: 22.25rem;
      -webkit-filter: blur(2.625rem);
      filter: blur(2.625rem);
    }

    &-img {
      width: 100%;
      transition: var(--transition-slow);
      z-index: 2;
      position: relative;
    }

    &-content {
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 1.25rem 0.75rem;
      backdrop-filter: blur(1.5625rem);
      -webkit-backdrop-filter: blur(1.5625rem);
      color: white;
      transform: translateY(57px);
      transition: var(--transition-slow);

      &-header {
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 900;

        svg {
          width: 1.375rem;
          height: 1.375rem;
        }
      }

      &-desc {
        height: 2.25rem;
        color: var(--6q1sr2);
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        padding-bottom: 10px;
      }

      .btn {
        border: none;
        min-width: max-content;
      }
    }
  }

  .kb-category-item {

  }

  .kb-category-item:hover {
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.04);
    transition: 0.1s ease-in;
    border: 1px dashed rgba(161, 57, 57, 0.87);
    cursor: pointer;
  }

  .kb-category-item {
    transition: 0.1s ease-out;
    border: none
  }
}
</style>



<style lang="scss">
.kb-category-svg-icon{
  &-casino{
    path{
      fill: #6200f6;
    }
  }

  &-sports{
    path{
      fill: #04f350;
    }
  }

  &-racing{
    path{
      fill: #ea8003;
    }
  }

  &-trading{
    path{
      fill: #51e61d;
    }
  }

}
</style>