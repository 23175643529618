<script>
import {BButton} from "bootstrap-vue";
import {computed} from "@vue/composition-api";

export default {
  components: {BButton},
  props: {
    items: [],
    settings: {}
  },
  name: "itemList",

  setup(props, {emit}) {

    const onEdit = (item) => {
      emit('edit', item)
    }

    const onAdd = () => {
      emit('add')
    }

    const onDelete = (id) => {
      emit('delete', id)
    }

    const getButtonsCount = computed(() => {
      return props.items.length || 0;
    });


    return {
      onAdd,
      onEdit,
      onDelete,
      getButtonsCount
    }
  }
}
</script>

<template>
  <div v-if="items">
    <div class="row">

    </div>
    <div class="mx-1">
      <h6>{{ $t('labels.links') }}</h6>
    </div>

    <div class="mx-1" v-if="settings.status === 'list'">
      <div class="d-flex mt-2 justify-content-end">
        <b-button-group class="w-100">
          <b-button variant="outline-primary" :disabled="getButtonsCount >= 12" @click="onAdd()">{{
              $t('add_link')
            }}
          </b-button>
          <b-button variant="primary">{{ getButtonsCount }}/12</b-button>
        </b-button-group>
      </div>
    </div>

    <b-list-group class="mt-1 mx-1" flush style="max-height: 380px; overflow-y: scroll">
      <b-list-group-item class="d-inline flex bg-transparent" v-for="(item, index) in items" :key="index">
        <div class="d-inline">
          <small>{{ item.text }}</small>
        </div>
        <div class="float-right">
          <b-button-group size="sm">
            <b-button @click="onEdit(item)" variant="outline-secondary" class="border-0">
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button @click="onDelete(item.id)" variant="outline-secondary" class="border-0">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </b-button-group>
        </div>
      </b-list-group-item>
    </b-list-group>

    <div  v-if="items.length === 0" class="py-3 text-center">
      {{ $t('list_empty') }}
    </div>

  </div>

</template>

<style scoped lang="scss">

</style>