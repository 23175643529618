<script>

export default {
  props: {
    game: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<template>
  <a class="game">
    <img :src="game.url_image" :alt="'game image'" />
  </a>
</template>

<style lang="scss" scoped>
.game {
  aspect-ratio: 4 / 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
