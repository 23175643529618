<template>
  <!--  <v-slide-y-transition appear>-->
  <div
    class="rounded-md h-12 flex flex-row items-center p-2 gap-2"
    :style="{ maxHeight: '80px', backgroundColor: colorConfig.bgColor }"
  >
    <div class="font-weight-bold" :style="{ color: colorConfig.primaryTextColor }">
      {{ toolbarTitle.toUpperCase() }}
    </div>

    <div class="flex items-center justify-center flex-1">
      <span class="font--bold">All</span>
    </div>
  </div>
  <!--  </v-slide-y-transition>-->
</template>

<script>
/*eslint-disable*/

export default {
  name: "LobbyToolbar",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          bgColor: "#1f93ff",
          primaryTextColor: "#000b28",
          secondaryTextColor: "#dadada",
        };
      },
    },
    toolbarTitle: {
      type: String,
      default: "n/a",
    },
  },
};
</script>

<style scoped></style>
