import { render, staticRenderFns } from "./ApostalaHomePage.vue?vue&type=template&id=07cfc202&scoped=true"
import script from "./ApostalaHomePage.vue?vue&type=script&lang=js"
export * from "./ApostalaHomePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07cfc202",
  null
  
)

export default component.exports