<script>
import {BButton, BCol, BFormGroup, BFormInput} from "bootstrap-vue";
import {computed, ref, watch} from "@vue/composition-api";
import RightSectionItem
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/FooterManagement/components/rightSectionItem.vue";

export default {
  name: "rightSection",
  components: {RightSectionItem, BButton, BCol, BFormGroup, BFormInput},

  props: {
    sections: []
  },


  setup(props, {emit}) {
    const settings = ref({
      status: 'list',
      items: {
        status: 'list',
      }
    })
    const formValid = ref(false)

    const index = ref(null)

    const section = ref({
      title: '',
      items: []
    })

    const onEdit = (value, i) => {
      section.value = {...value}
      index.value = i
      settings.value.status = 'form'
    }

    const onDelete = (index) => {
      emit('deleteSection', index)
    }

    const onAdd = () => {
      index.value = null
      section.value = {title: '', items: []}
      settings.value.status = 'form'
    }

    const count = computed(() => {
      return props.sections.length || 0;
    });

    watch(() => section, () => {
      checkForm()
    }, {deep: true})

    const checkForm = () => {
      const required = ['title']
      formValid.value = false

      required.forEach(key => {
        if (section.value[key]) {
          formValid.value = true
        }
      })
    }

    const itemHandler = () => {
      if (!formValid.value) {
        return
      }

      if (index.value !== null) {
        emit('editSection', {value: section.value, index: index.value})
      } else {
        emit('saveSection', section.value)
      }

      settings.value.status = 'list'
    }

    const onCancel = () => {
      section.value = {title: '', items: []}
      settings.value.status = 'list'
      index.value = null
    }

    const onChangeStatus = (status) => {
      settings.value.items.status = status
    }

    const onDeleteItem = (index) => {
      section.value.items.splice(index, 1);
    }

    const onEditItem = ({value, index}) => {
      section.value.items[index] = {...value}
    }

    const onAddItem = (item) => {
      section.value.items.push(item)
    }

    return {
      onEditItem,
      onAddItem,
      section,
      onEdit,
      onDelete,
      settings,
      onChangeStatus,
      onAdd,
      onDeleteItem,
      count,
      formValid,
      itemHandler,
      onCancel
    }
  }
}
</script>

<template>
  <div>

    <div v-if="settings.status === 'form'">
      <div class="mx-1">
        <h6>Section</h6>
      </div>

      <b-col class="border-md-left mt-1" cols="12">
        <b-form-group label-for="title">
          <template #label>{{ $t("title") }}</template>
          <b-form-input
              id="title"
              required
              :disabled="settings.items.status === 'form'"
              type="text"
              v-model="section.title">
          </b-form-input>
        </b-form-group>
      </b-col>

      <right-section-item @delete=onDeleteItem($event)
                          @status="onChangeStatus($event)"
                          @add="onAddItem($event)"
                          @edit="onEditItem($event)"
                          :items="section.items">
      </right-section-item>

      <div v-if="settings.items.status === 'list'" class="row px-1 pb-2">
        <div class="col-md-6">
          <b-button :disabled="!formValid"
                    class="w-100 mt-1"
                    size="sm"
                    @click="itemHandler"
                    variant="primary">
            Save Section
          </b-button>
        </div>
        <div class="col-md-6">
          <b-button size="sm" class="w-100 mt-1" variant="secondary" @click="onCancel()">
            {{ $t("buttons.cancel") }}
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="sections && settings.status === 'list'" class="pb-2">

      <div class="d-flex justify-content-end mx-1">
        <b-button-group class="w-100">
          <b-button variant="outline-primary" :disabled="count >= 4" @click="onAdd()">
            {{
              $t('add_item')
            }}
          </b-button>
          <b-button variant="primary">{{ count }}/4</b-button>
        </b-button-group>
      </div>

      <b-list-group class="mx-1" flush>
        <b-list-group-item class="d-inline flex" style="background: none" v-for="(section, index) in sections"
                           :key="index">
          <div class="d-inline text-truncate"><strong class="text-caption">{{ section.title }}</strong></div>
          <div class="float-right">
            <b-button-group size="sm">
              <b-button v-b-toggle="['collapse-' + index]" variant="outline-secondary" class="border-0">
                <feather-icon icon="ListIcon"/>
              </b-button>
              <b-button @click="onEdit(section, index)" variant="outline-secondary" class="border-0">
                <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button @click="onDelete(index)" variant="outline-secondary" class="border-0">
                <feather-icon icon="TrashIcon"/>
              </b-button>
            </b-button-group>

          </div>

          <!-- Elements to collapse -->
          <b-collapse :visible="false" :id="'collapse-' + index" class="mt-2">
            <b-card no-body class="px-1">
              <b-list-group flush>
                <b-list-group-item style="background: none"
                                   class="d-inline flex"
                                   v-for="(item, index) in section.items"
                                   :key="index">
                  <div class="d-inline"><p class="text-caption">{{ item.content }}</p></div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-collapse>
        </b-list-group-item>

      </b-list-group>
    </div>
  </div>

</template>

<style scoped lang="scss">
.list-group-item {
  background: transparent !important;
  padding-inline: 0 !important;
  font-size: 12px;
}

.list-group-item p {
  margin-bottom: 0;
}

.btn-outline-secondary {
  padding: 5px 8px;
  border: none !important;
}
</style>