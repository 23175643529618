export const RequestBuilder = () => {

    const clean = (req) => {
        let request = JSON.parse(JSON.stringify(req))
        const notAllowedKeys = ['_id', 'createdAt', '__v', 'updatedAt', 'isReadonly', 'softDelete']

        notAllowedKeys.forEach(key => {
            if (request.hasOwnProperty(key)) {
                delete request[key];
            }
        });

        return request
    }

    return {clean}

}