<template>
  <div class="apostala-login">
    <b-button size="sm" :style="buttonConfig">
      Login
      <b-icon class="icon" icon="arrow-right"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "ApostalaLogin",
  props: ["config"],

  computed: {
    buttonConfig() {
      return `
        color: ${this.config.color} !important;
        background-color: ${this.config.background_color} !important;
        border: none !important;
      `
    },
  },
};
</script>
<style scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style scoped lang="scss">
.apostala-login {
  button {
    .icon {
      transition: all 0.2s ease;
    }
    &:hover {
      filter: brightness(0.9);
      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}

#apostala-login-modal {
  a {
    color: var(--primary);
  }

  button {
    background-color: var(--primary) !important;
    color: var(--header-c) !important;
    border: none !important;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.v-overlay{
  display: none !important;
}
</style>
