<template>
  <div class="preview" ref="preview">
    <my-iframe
      class="shadow-lg rounded-10"
      :style="{
        ...previewSize,
        border: 'none',
        backgroundColor: 'red',
        transform: scale + ' translate(0,0)',
      }"
    >
      <html>
        <head v-html="head"></head>
        <body>
          <NewLobby :lobby="lobby" />
          <div
            v-html="
              `
							<script src='https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js' integrity='sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN' crossorigin='anonymous'></script>
							<script src='https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js' integrity='sha384-+sLIOodYLS7CIrQpBjl+C7nPvqq+FbNUBDunl/OZv93DB7Ln/533i8e/mZXLi/P+' crossorigin='anonymous'></script>
            `"
          ></div>
        </body>
      </html>
    </my-iframe>
  </div>
</template>

<script>
import MyIframe from "@/views/components/lobby/MyIframe.js";
import NewLobby from "@/views/components/lobby/NewLobby.vue";
export default {
  name: "LobbyPreview",
  components: {
    NewLobby,
    MyIframe,
  },
  data() {
    return {
      head: "",
    };
  },
  computed: {
    scale() {
      return {
        phone: "",
        tablet: "scale(.9)",
        laptop: "scale(.9)",
      }[this.size];
    },
    previewSize() {
      return {
        phone: { width: "350px", height: "500px" },
        tablet: { width: "750px", height: "800px" },
        laptop: { width: "1024px", height: "800px" },
      }[this.size];
    },
  },
  beforeMount() {
    this.head = `
		<head>
			${document.head.innerHTML}
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
<link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.15/tailwind.min.css" rel="stylesheet" />
			
		</head>`;
  },
  props: ["size", "lobby"],
};
</script>
<style scoped>
.rounded-10 {
  border-radius: 10px;
}

.preview {
  width: 100%;
  overflow: scroll;
  height: 40vh;
  display: grid;
  /*place-items: center; */
  /*padding: 10px;*/
}
.preview::-webkit-scrollbar {
  -webkit-appearance: none;
}
.preview::-webkit-scrollbar:vertical {
  width: 10px;
}
.preview::-webkit-scrollbar-button:increment,
.preview::-webkit-scrollbar-button {
  display: none;
}

.preview::-webkit-scrollbar:horizontal {
  height: 10px;
}

.preview::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.preview::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>