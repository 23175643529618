<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {computed, ref, watch} from "@vue/composition-api";
import ItemList from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/itemList.vue";
import {ValidationProvider} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {useToast} from "vue-toastification/composition";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "LeftSection",
  components: {
    ImageFieldForm,
    BFormSelect,
    ValidationProvider,
    ItemList, BRow, BButton, BFormInput, BFormInvalidFeedback, BFormGroup, BCol, vSelect
  },

  props: {
    type: null,
    items: [],
  },

  setup(props, {emit}) {
    const settings = ref({
      status: 'list',
    })

    const index = ref(null)
    const formValid = ref(false)
    const toast = useToast();

    const item = ref({
      content: ''
    })

    const onCancel = () => {
      item.value = {content: ''}
      settings.value.status = 'list'
      index.value = null
    }

    const itemHandler = () => {
      if (!formValid.value) {
        return
      }

      if (index.value !== null) {
        emit('editItem', {value: item.value, index: index.value})
      } else {
        emit('saveItem', item.value)
      }

      settings.value.status = 'list'
    }


    watch(() => item, () => {
      checkForm()
    }, {deep: true})


    const checkForm = () => {
      const required = ['content']
      formValid.value = false

      required.forEach(key => {
        if (item.value[key]) {
          formValid.value = true
        }
      })
    }

    const count = computed(() => {
      return props.items.length || 0;
    });

    const onEdit = (value, i) => {
      item.value = {...value}
      index.value = i
      settings.value.status = 'form'
    }

    const onDelete = (index) => {
      emit('deleteItem', index)
    }

    const onAdd = () => {
      index.value = null
      item.value = {content: ''}
      settings.value.status = 'form'
    }

    return {
      onCancel,
      formValid,
      itemHandler,
      settings,
      item,
      onDelete,
      onEdit,
      count,
      onAdd
    }
  },

  created() {
  }
}
</script>

<template>
  <div>

    <div v-if="settings.status === 'form'">

      <b-col class="border-md-left" cols="12">
        <b-form-group label-for="text">
          <template #label>{{ $t("content") }}</template>
          <b-form-input
              id="title"
              required
              type="text"
              v-model="item.content">

          </b-form-input>
        </b-form-group>
      </b-col>

      <div class="row px-1 pb-2">
        <div class="col-6">
          <b-button :disabled="!formValid"
                    class="w-100 mt-1"
                    @click="itemHandler"
                    variant="primary">
            save
          </b-button>
        </div>
        <div class="col-6">
          <b-button class="w-100 mt-1" variant="secondary" @click="onCancel()">
            {{ $t("buttons.cancel") }}
          </b-button>
        </div>
      </div>

    </div>

    <!--- ITEMS LIST -->

    <div v-if="settings.status === 'list'" class="pb-2">

      <div class="d-flex justify-content-end mx-1">
        <b-button-group class="w-100">
          <b-button variant="outline-primary" :disabled="count >= 2" @click="onAdd()">
            {{
              $t('add_item')
            }}
          </b-button>
          <b-button variant="primary">{{ count }}/2</b-button>
        </b-button-group>
      </div>

      <b-list-group class="mt-1 mx-1" flush
                    style="max-height: 380px; overflow-y: scroll">


        <b-list-group-item style="overflow-x: hidden;"
                           class="d-inline flex px-0 bg-transparent"
                           v-for="(item, index) in items" :key="index">

          <div class="row">
            <div class="col item-content">
              <small>{{ item.content }}</small>
            </div>

            <div class="col">
              <b-button-group size="sm">
                <b-button @click="onEdit(item, index)" variant="outline-secondary" class="border-0">
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button @click="onDelete(index)" variant="outline-secondary" class="border-0">
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </b-button-group>
            </div>
          </div>

        </b-list-group-item>
      </b-list-group>

      <div v-if="items.length === 0" class="py-3 text-center">
        {{ $t('list_empty') }}
      </div>
    </div>


  </div>
</template>

<style scoped lang="scss">
.item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>