<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { createCssVar } from "@/views/components/whitelabel-templates/common/utils/createCssVar";
import apStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import ApostalaHeader from "./components/common/headers/ApostalaHeader.vue";
import MainLayout from "./components/layouts/MainLayout.vue";
import ApostalaFooter from "./components/common/footers/ApostalaFooter.vue";
import ApostalaHomePage from "@/views/components/whitelabel-templates/Apostala/views/homePages/ApostalaHomePage.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import ApostalaNav from "@/views/components/whitelabel-templates/Apostala/components/common/navMenu/ApostalaNav.vue";
import AppNav from "@/views/components/whitelabel-templates/App/components/common/navMenu/AppNav.vue";

const MODULE_AP_STORE_NAME = "ap-store";
const MODULE_WHITELABEL_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "ApostalaTemplate",
  components: {
    AppNav,
    ApostalaNav,
    UserProfile,
    ApostalaHomePage,
    ApostalaHeader,
    ApostalaFooter,
    MainLayout,
  },
  setup() {
    // Register modules
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, apStoreModule);
    if (!store.hasModule(MODULE_WHITELABEL_STORE_NAME))
      store.registerModule(
        MODULE_WHITELABEL_STORE_NAME,
        whitelabelTemplatesModule
      );

    const el = ref(null);

    const editTemplate = computed(
      () => store.state[MODULE_WHITELABEL_STORE_NAME].editTemplate
    );
    const preview = computed(() => store.state[MODULE_AP_STORE_NAME].preview);

    // Computed properties for configs
    const headerConfig = computed(() => editTemplate.value.templateData.header);
    const authConfig = computed(() => editTemplate.value.templateData.auth);
    const typeConfig = computed(() => editTemplate.value.templateData.type);
    const currenciesConfig = computed(
      () => editTemplate.value.templateData.currencies
    );
    const usersConfig = computed(() => editTemplate.value.templateData.users);
    const menuConfig = computed(() => editTemplate.value.templateData.menu);
    const footerConfig = computed(() => editTemplate.value.templateData.footer);
    const carouselConfig = computed(() => editTemplate.value.templateData.carousel);
    const productsConfig = computed(() => editTemplate.value.templateData.products);

    // Computed properties for colors
    const mainColors = computed(
        () => editTemplate.value.templateData.main.colors
    );

    // Computed properties for header colors
    const headerColors = computed(
        () => editTemplate.value.templateData.header
    );
    
    // Computed properties for colors (if needed)
    const userProfileColors = computed(
      () => editTemplate.value.templateData.user_profile?.colors || {}
    );

    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--primary": mainColors.value.primaryColor,
        "--header-bg": headerColors.value.background_color,
        "--header-c": headerColors.value.background_color,
        "--tag-bg": mainColors.value.tags_background_color,
        "--tag-c": mainColors.value.tags_text_color,
        "--providers-hover-bg": mainColors.value.providers_hover_color,
        "--providers-bg": mainColors.value.providers_background_color,
        "--providers-c": mainColors.value.providers_text_color,
        "--providers-color-primary": mainColors.value.providers_primary_color,
        "--setting-bg-primary": userProfileColors.value.bgPrimary,
        "--setting-bg-secondary": userProfileColors.value.bgSecondary,
        "--setting-border": userProfileColors.value.colorBorder,
        "--setting-color-primary": userProfileColors.value.colorPrimary,
        "--setting-color-primary-text":
          userProfileColors.value.colorPrimaryText,
        "--setting-color-secondary": userProfileColors.value.colorSecondary,
      });
    };

    watch(userProfileColors, setCssVar, {
      deep: true,
      immediate: true,
    });

    onMounted(setCssVar);

    return {
      el,
      preview,
      editTemplate,
      headerConfig,
      authConfig,
      typeConfig,
      currenciesConfig,
      usersConfig,
      menuConfig,
      footerConfig,
      carouselConfig,
      productsConfig
    };
  },
};
</script>

<template>
  <main ref="el">
    <apostala-header
      :header="headerConfig"
      :auth="authConfig"
      :type="typeConfig"
      :currencies="currenciesConfig"
      :users="usersConfig"
      v-if="headerConfig"
      :menu="menuConfig"
      ref="layoutHeader"
      :app-nav-key="''"
    />
    <main-layout>
      <user-profile v-show="preview === 'profile'" />
      <apostala-home-page :products="productsConfig" :carousel="carouselConfig" v-show="preview === 'general'" />
    </main-layout>
    <apostala-footer :footer="footerConfig" v-if="footerConfig" />
  </main>
</template>

<style scoped></style>
