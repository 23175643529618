<template>
  <!-- eslint-disable -->

  <div
    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-4 gap-8"
    ref="gridGamesWrapper"
    @click="onclick()"
  >
    <!-- Columns -->
    <div v-for="(item, index) in brandList" :key="index">
      <div class="flex flex-row justify-between items-center pt-2 px-1 pb-2">
        <span class="font-weight-bold text-truncate mr-2">{{
          item.brandName.toUpperCase()
        }}</span>
        <button
          :class="[configColor.buttonClass || '']"
          :style="configColor.buttonStyle"
          style="font-size: 10px"
          class="text-white px-3 py-1 rounded shadow-none font--extra-bold"
        >
          See all
        </button>
      </div>

      <!--Content-->
      <div
        class="flex flex-row flex-wrap h-72 gap-4"
        v-if="item.gamesPreview.length >= 3"
      >
        <div
          v-for="(item, index) in item.gamesPreview"
          :key="index"
          class="h-1/2 w-100 rounded-l-lg rounded-tr-3xl rounded-br-lg"
          :class="[index === 0 ? 'w-full' : 'flex-1']"
          :style="{ borderColor: configColor.borderColor }"
          style="border-width: 3px; max-width: 100%; overflow: hidden"
        >
          <div
            class="flex flex-row items-end h-100 w-100 gap-1 relative"
            style="height: 100%"
          >
            <img
              alt="Game Image"
              :style="{
                background: configColor.bgColor,
                width: '100%',
                height: '100%',
              }"
              class="object-cover bg-no-repeat bg-contain transform hover:scale-105 transition duration-500"
              :src="item.url_image"
            />

            <section
              style="cursor: pointer"
              class="absolute right-2 rounded-full bg-pink-700"
              :class="[index === 0 ? 'bottom-2 p-2' : 'top-2 pa-1']"
            >
              <span
                :style="index === 0 ? 'font-size: 16px' : 'font-size: 14px'"
                class="text-center text-xs"
                >mdi-heart</span
              >
            </section>

            <div class="absolute flex flex-row gap-1 items-center p-1">
              <section class="flex flex-col overflow-hidden">
                <span class="leading-3 text-sm truncate pt-1">{{
                  item.gameName || ""
                }}</span>
                <span style="font-size: 10px" class="font--light truncate">{{
                  item.brandName || ""
                }}</span>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-row flex-wrap h-72 gap-4"
        v-else-if="item.gamesPreview.length > 0"
      >
        <div
          class="w-100 rounded-l-lg rounded-tr-3xl rounded-br-lg"
          :style="{ borderColor: configColor.borderColor }"
          style="border-width: 3px; max-width: 100%; overflow: hidden"
        >
          <div class="flex flex-row items-end gap-1" style="height: 100%">
            <img
              alt="Game Image"
              class="object-cover bg-no-repeat bg-contain h-100 w-100 transform hover:scale-105 transition duration-500"
              :style="{ background: configColor.bgColor, height: '100%' }"
              :src="item.gamesPreview[0].url_image"
            />
            <div class="absolute flex flex-row gap-1 items-center p-2">
              <section class="flex flex-col">
                <span class="leading-3 text-sm pt-1">{{
                  item.gameName || ""
                }}</span>
                <span style="font-size: 10px">{{ item.brandName || "" }}</span>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row flex-wrap h-72 gap-4" v-else>
        <div
          class="h-100 rounded-l-lg rounded-tr-3xl rounded-br-lg bg-gray-500 text-black flex justify-center items-center"
          :style="{
            width: '100%',
            background: configColor.bgColor,
            borderColor: configColor.borderColor,
          }"
          style="border-width: 3px; max-width: 100%; overflow: hidden"
        >
          <span class="text-3xl font--light">Empty...</span>
        </div>
      </div>
      <!--Content-->
    </div>
    <!-- Columns -->
  </div>
</template>

<script>
export default {
  name: "BrandGamesPreview",
  props: {
    configColor: {
      type: Object,
      default() {
        return {
          buttonStyle: "",
          buttonClass: "",
        };
      },
    },

    brandList: {
      type: Array,
      default() {
        return [
          {
            brandName: "NETGAMING",
            gamesPreview: [
              { url_image: "https://picsum.photos/600/400/?image=0", id: "" },
              { url_image: "https://picsum.photos/600/400/?image=1", id: "" },
              { url_image: "https://picsum.photos/600/400/?image=2", id: "" },
            ],
          },
          {
            brandName: "NETGAMING 1",
            gamesPreview: [
              { url_image: "https://picsum.photos/600/400/?image=0", id: "" },
            ],
          },
          {
            brandName: "NETGAMING 2",
            gamesPreview: [],
          },
          {
            brandName: "NETGAMING 3",
            gamesPreview: [
              { url_image: "https://picsum.photos/600/400/?image=3", id: "" },
              { url_image: "https://picsum.photos/600/400/?image=4", id: "" },
              { url_image: "https://picsum.photos/600/400/?image=5", id: "" },
            ],
          },
        ];
      },
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onclick() {
      console.log("a");
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped></style>
