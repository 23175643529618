<script>
export default {
  name: "ApostalaLobbyProviders",
  data() {
    return {
      showAll: false,
      swiperInstance: null,
    };
  },
  mounted() {
    this.swiperInstance = new this.$root.$swiper(this.$refs.swiperSuppliers, {
      init: true,
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 2,
      breakpoints: {
        960: {
          slidesPerView: 11,
          spaceBetween: 11,
        },
        600: {
          slidesPerView: 7,
          spaceBetween: 7,
        },
        400: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
      },
    });
  },
};
</script>

<template>
  <section class="items-center p-2 flex gap-x-10 suppliers">
    <b-button
        size="sm"
        class="flex-shrink-0"
        v-b-modal.apostala-suppiers-modal> Ver todos
    </b-button>


    <div class="swiper suppliers-swiper" ref="swiperSuppliers">
      <div class="swiper-wrapper">
        <a
            v-for="index in 15"
            :key="index"
            class="flex items-center overflow-hidden swiper-slide text-decoration-none flex-column gap-y-1"
        >
          <div class="w-10 h-10 overflow-hidden rounded-full hover14">
            <figure class="transition duration-200 transform hover:scale-110">
              <img
                  class="w-full h-auto"
                  alt="supplier-image"
                  src="https://www.argenplay.com/assets/template/slots/lobbys/luxury/img/icon/rubyplay.svg"
              />
            </figure>
          </div>
          <span class="text-xs">Provider {{ index }}</span>
        </a>
      </div>
    </div>

    <b-modal id="apostala-suppiers-modal" centered hide-header hide-footer>
      <div class="text-lg">...</div>
    </b-modal>
  </section>
</template>

<style scoped lang="scss">
.suppliers {
  background-color: var(--providers-bg);
  color: var(--providers-c);

  a {
    color: var(--providers-c);
    transition: color .2s ease-in-out;

    &:hover {
      color: var(--primary);
    }

    figure {
      background-color: var(--providers-color-primary);
    }
  }

  button {
    background-color: var(--providers-color-primary) !important;
    border: 0 !important;
    border-radius: 0.2rem !important;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

.hover14 figure {
  position: relative;
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%
  );
  transform: skewX(-25deg);
}

.hover14 figure:hover::before {
  animation: shine 0.8s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
</style>
