<script>
export default {
  name: "TagsButton",

  props: ['color', 'textColor', 'text'],

  computed: {
    tagsButtonStyle() {
      return {
        "--background-color": this.color || "#4503dc",
        "--color": this.textColor || "#fff",
        backgroundColor: this.color || "#4503dc",
        backgroundSize: "100%",
      };
    },
  }
}
</script>

<template>
  <button :style="tagsButtonStyle" class="button">
    {{ this.text }}
  </button>
</template>

<style scoped lang="scss">
.button {
  outline: none;
  border: none;
  height: 2.5em;
  padding: 0 2.5rem;
  border-radius: 0.375rem;
  background: var(--background-color);
  color: var(--color);
  -webkit-box-shadow: 0 0.4rem 0 0 var(--background-color);
  box-shadow: 0 0.4rem 0 0 var(--background-color);
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: sans-serif;
  font-weight: 700;
}

.button:active {
  -webkit-transform: translateY(0.3rem);
  -ms-transform: translateY(0.3rem);
  transform: translateY(0.3rem);
  -webkit-box-shadow: 0 0.2rem 0 0 var(--background-color);
  box-shadow: 0 0.2rem 0 0 var(--background-color);
}
</style>