<script>
import KBVictory from "./KBVictory.vue";

export default {
  data() {
    return {
      swiperInstance: null,
      victories: [
        {
          id: 1,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 1",
          value: 25000,
          badge: "diamond",
        },
        {
          id: 2,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 2",
          value: 15000,
          badge: "platinum",
        },
        {
          id: 3,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 2",
          value: 55000,
          badge: "gold",
        },
        {
          id: 4,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 4",
          value: 75000,
          badge: "diamond",
        },
        {
          id: 5,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 5",
          value: 34500,
          badge: "platinum",
        },
        {
          id: 6,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 6",
          value: 23450,
          badge: "gold",
        },
        {
          id: 7,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 7",
          value: 24560,
          badge: "diamond",
        },
        {
          id: 8,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 8",
          value: 65780,
          badge: "platinum",
        },
        {
          id: 9,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 9",
          value: 97890,
          badge: "gold",
        },
        {
          id: 10,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 10",
          value: 75240,
          badge: "diamond",
        },
        {
          id: 11,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 11",
          value: 56723,
          badge: "platinum",
        },
        {
          id: 12,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 12",
          value: 55000,
          badge: "gold",
        },
        {
          id: 13,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 13",
          value: 25600,
          badge: "diamond",
        },
        {
          id: 14,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 14",
          value: 78900,
          badge: "platinum",
        },
        {
          id: 15,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 15",
          value: 45670,
          badge: "gold",
        },
        {
          id: 16,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 16",
          value: 73400,
          badge: "diamond",
        },
        {
          id: 17,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 17",
          value: 56730,
          badge: "platinum",
        },
        {
          id: 18,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 18",
          value: 25000,
          badge: "gold",
        },
        {
          id: 19,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 19",
          value: 26969,
          badge: "diamond",
        },
        {
          id: 20,
          image: "81c47bf6-3761-451b-b637-c63d0d554dcc.jpg",
          user: "User 20",
          value: 66880,
          badge: "platinum",
        },
      ],
    };
  },
  mounted() {
    this.swiperInstance = new this.$root.$swiper(this.$refs.swiperVictories, {
      autoHeight: true,
      slidesPerView: 4,
      loop: true,
      speed: 800,
      allowTouchMove: false,
      autoplay: {
        delay: 300,
        disableOnInteraction: false,
      },
      breakpoints: {
        992: {
          slidesPerView: 12,
        },
      },
    });
  },
  components: {
    KBVictory,
  },
};
</script>

<template>
  <div class="kb-victories">
    <div class="kb-victories-header">
      <div class="kb-victories-header-live" style="display: none"></div>
      <div class="kb-victories-header-title">Grandes Victorias Recientes</div>
    </div>

    <div class="kb-victories-content">
      <div class="kb-victories-filters">
        <div class="kb-victories-filters-item active">Todo</div>
        <div class="kb-victories-filters-item">Original</div>
        <div class="kb-victories-filters-item">Tragamonedas</div>
        <div class="kb-victories-filters-item">Casino en vivo</div>
      </div>
      <div
        class="kb-victories-swiper swiper"
        ref="swiperVictories"
        @mouseenter="swiperInstance.autoplay.stop()"
        @mouseleave="swiperInstance.autoplay.start()"
      >
        <div class="swiper-wrapper">
          <KBVictory
            v-for="victory in victories"
            :key="victory.id"
            :victory="victory"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.kb-victories {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 10px;
  max-width: 100vw;
  margin-top: 30px;

  @include responsive(lg) {
    padding: 0 50px;
  }

  &-header {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: var(--title-color);

    &-live {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: var(--primary-color);
      margin: 0 8px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 250%;
        height: 250%;
        border-radius: 100%;
        background-color: var(--primary-color);
        opacity: 0.5;
        animation: live 2s infinite;
      }

      @keyframes live {
        from {
          transform: translate(-50%, -50%) scale(0);
          opacity: 0.5;
        }

        50% {
          opacity: 0.5;
        }

        to {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }

  &-content {
    background-color: var(--standard-color);
    border-radius: var(--radius);
    padding: 6px 0;
    width: 100%;
  }

  &-filters {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 6px 10px 6px;
    overflow: auto;
    max-width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      padding: 5px 10px 8px 10px;
      border-radius: var(--radius);
      cursor: pointer;
      min-width: max-content;
      line-height: 100%;
      color: var(--text-color);

      &:hover {
        background-color: color-mix(
          in srgb,
          var(--placeholder-color) 50%,
          transparent 100%
        );
      }

      &.active {
        background-color: var(--placeholder-color);
        color: white;
        font-weight: 600;
      }
    }
  }

  &-swiper {
    width: calc(100vw - 20px);

    @include responsive(lg) {
      width: 100%;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 7px;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      opacity: 0.7;
    }

    &-img {
      width: 100%;
      border-radius: var(--radius);
    }

    &-user {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: 0.75rem;
      color: white;

      img {
        width: 0.875rem;
        height: 0.875rem;
      }

      &-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-value {
      color: var(--primary-color);
      font-weight: 600;
      font-size: 0.8125rem;
      text-align: center;
    }
  }
}
</style>
