<script>
import {BCol, BFormGroup, BRow} from "bootstrap-vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";

export default {
  name: "logoContent",
  components: {BRow, ImageFieldForm, BCol, BFormGroup},
  props: {
    logo: {
      hide: false,
      href: '',
    }
  },

  setup(props, {emit}) {
    const onUploadedImage = (value) => {
      emit('change', value)
    }

    const errorInLoadImage = (value) => {
      emit('error', value)
    }

    const onClearImage = (value) => {
      emit('clear', value)
    }


    return {
      onUploadedImage,
      errorInLoadImage,
      onClearImage,
    }
  }
}
</script>

<template>
  <div>
    <hr>
    <h5>Logo</h5>
    <div class="row px-1 mt-2">

      <span>{{ $t('labels.image') }}</span> <br/>
      <span>250x50</span>
      <image-field-form
          :single-button="true"
          :text="$t('labels.image')"
          class="w-100 mt-1"
          size-validation="250x50"
          :value="logo.href"
          path="headers/logo"
          @uploadedImage="onUploadedImage($event)"
          @error="errorInLoadImage($event)"
      >
      </image-field-form>

      <div v-if="logo.href" class="w-100">
        <img
            style="max-width: 100%"

            :src="logo.href"
            alt="Uploaded Image"
            class="mt-1 rounded"
        />
        <b-button class="close-btn" @click="onClearImage($event, 'logo')" variant="outline-primary" size="sm">X
        </b-button>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>