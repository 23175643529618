<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  // directives: {
  //   "adding-styles": {
  //     inserted: function (el, binding, vnode) {
  //       const componentStyles = vnode.context.$options.style || {};
  //       // Utiliza los estilos del componente Vue para aplicar cambios o generar nuevos estilos dinámicamente
  //       // Por ejemplo:
  //       const styles = {
  //         ...componentStyles,
  //         // color: "white",
  //         // padding: "10px",
  //         // borderRadius: "5px",
  //       };
  //       console.log(componentStyles);
  //       alert("a");
  //       const styleElement = document.createElement("style");
  //       styleElement.textContent = styles;
  //       el.appendChild(styleElement);
  //     },
  //   },
  // },
  directives: {
    "adding-styles": {
      inserted: function (el) {
        const style = `
      .push-button {
        position: relative;
        transition: all 0.3s ease;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        padding: 3px 8px;
        border-radius: 4px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #ffff;
        gap: 8px;
        font-weight: bold;
        border: 3px solid #ffffff4d;
        outline: none;
        overflow: hidden;
        font-size: 10px;
      }

      .icon {
        width: 13px;
        height: 13px;
        transition: all 0.3s ease-in-out;
      }

      .push-button:hover {
        transform: scale(1.05);
        border-color: #fff9;
      }

      .push-button:hover .icon {
        transform: translate(4px);
      }

      .push-button:hover::before {
        animation: shine 1s ease-out infinite;
      }

      .push-button::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100%;
        background-image: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0) 70%
        );
        top: 0;
        left: -100px;
        opacity: 0.6;
      }

      @keyframes shine {
        0% {
          left: -100px;
        }

        60% {
          left: 100%;
        }

        to {
          left: 100%;
        }
      }
    `;

        const styleElement = document.createElement("style");
        styleElement.textContent = style;
        el.appendChild(styleElement);
      },
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
<template>
  <button
    class="push-button"
    v-adding-styles
    :style="{ backgroundColor: this.backgroundColor, color: this.color }"
  >
    Ingresar
    <feather-icon class="icon" icon="ArrowRightIcon" />
  </button>
</template>

<style scoped></style>
