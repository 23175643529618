import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchFooters(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/footers`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addFooter(ctx, headerData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/footers`,  headerData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateFooter(ctx, { id, footerData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/footers/${id}`, footerData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeFooter(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/footers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
