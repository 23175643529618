<template>
  <section
    class="flex flex-col items-center card-3d-effect lg:justify-center mt-12 rounded-xl px-4 h-48 sm:h-48 md:h-42 lg:h-48 2xl:h-56 relative grow"
    :style="wrapperSectionStyle"
  >
    <div class="w-full py-6 lg:px-4">
      <h2 class="text-2xl lg:text-4xl font-weight-bold" :style="cardTextStyle">
        {{ cardTitle.toUpperCase() }}
      </h2>
    </div>
    <div
      class="absolute w-8/12 sm:w-6/12 md:w-1/2 lg:w-2/4 2xl:w-5/12 bottom-0 lg:right-2"
      style="max-width: 580px"
    >
      <img class="object-contain" :src="img" alt="Imagen de la tarjeta" />
    </div>
  </section>
</template>

<script>
export default {
  name: "LobbyCard",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          bgColor: "#001a35",
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#dadada",
        };
      },
    },
    img: {
      type: String,
      default() {
        return "img/newLobby/img_10.png";
      },
    },
    cardTitle: {
      type: String,
      default: "n/a",
    },
  },
  computed: {
    wrapperSectionStyle() {
      return {
        "background-color": this.colorConfig.bgColor,
        "max-height": "340px",
      };
    },
    cardTextStyle() {
      return {
        color: this.colorConfig.primaryTextColor,
      };
    },
  },
};
</script>

<style scoped>
.grow {
  transition: all 0.1s ease-in-out;
}
.grow:active {
  transform: scale(0.97);
}
</style>
