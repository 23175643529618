<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import {computed, ref, onUnmounted} from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import ColorTableField from "@/views/components/ColorTableField.vue";
import ImageTableField from "@/views/components/ImageTableField.vue";
import carouselStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/CarouselManagement/store/carouselStoreModule";
import useCarouselList
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/CarouselManagement/useCarouselList";
import i18n from "@/libs/i18n";
import {
  showSuccessToast,
  showErrorToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {useToast} from "vue-toastification/composition";

export default {
  name: "CarouselList",
  components: {
    BButton,
    ImageTableField,
    BCard,
    BTable,
    BCol,
    BRow,
    BPagination,
    BFormInput,
    ColorTableField,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup(props, {emit}) {
    const CAROUSEL_AP_STORE_MODULE_NAME = "ap-carousel-store";
    const toast = useToast();

    if (!store.hasModule(CAROUSEL_AP_STORE_MODULE_NAME)) {
      store.registerModule(CAROUSEL_AP_STORE_MODULE_NAME, carouselStoreModule);
    }

    const isAddNewCarouselSidebarActive = ref(false);

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", {item, action});
    };

    const {
      fetchCarousels,
      perPage,
      currentPage,
      totalCarousels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCarouselListTable,
      refetchData,
      selectCarousel,
      isSelected,
      recordDeleted,
      loading,
      resolveCarouselStatusVariant,
    } = useCarouselList();

    const tableColumns = computed(() => [
      {key: "name", sortable: false, label: i18n.t("labels.name")},
      {key: "actions", label: i18n.t("labels.actions")},
    ]);

    const removeCarousel = async (id) => {
      try {
        await store.dispatch("ap-carousel-store/removeCarousel", {id});
        recordDeleted.value = !recordDeleted.value;
        showSuccessToast(toast, "Carousel", i18n.t("carousel_removed"));
      } catch (error) {
        console.error("Error removing carousel:", error);
        showErrorToast(toast, "Carousel", i18n.t("error_removing_carousel"));
      }
    };

    return {
      isAddNewCarouselSidebarActive,
      fetchCarousels,
      perPage,
      currentPage,
      totalCarousels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCarouselListTable,
      refetchData,
      isSelected,
      recordDeleted,
      loading,
      selectCarousel,
      prepareUpdateOrCreate,
      resolveCarouselStatusVariant,
      tableColumns,
      removeCarousel,
    };
  },
};
</script>

<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('buttons.search') + '...'"
              />
              <div>
                <b-button
                    variant="primary"
                    @click="prepareUpdateOrCreate(null, 'create')"
                >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
          :show="loading"
          :variant="$store.state.appConfig.layout.skin"
          blur="2"
          class="p-50"
      >
        <b-table
            ref="refCarouselListTable"
            class="position-relative"
            :items="fetchCarousels"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="this.$t('messages.nomatching')"
            :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(name)="data">
            <p>{{ data.item.name }}</p>
          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                @click="selectCarousel(data.item._id)"
                v-b-tooltip.hover.top="$t('labels.select')"
                size="sm"
            >
              <feather-icon
                  :icon="
                  isSelected(data.item._id) ? 'CheckCircleIcon' : 'CircleIcon'
                "
              />
            </b-button>

            <b-button
                v-if="isSelected(data.item._id)"
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-b-tooltip.hover.top="$t('tooltips.edit')"
                size="sm"
                @click="prepareUpdateOrCreate(data.item, 'edit')"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-if="!isSelected(data.item._id)"
                v-b-tooltip.hover.top="$t('tooltips.delete')"
                @click="removeCarousel(data.item._id)"
                size="sm"
            >
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalCarousels"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
