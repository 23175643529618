<script>
import KBAds from "../../components/modules/section/ads/KBAds.vue";
import KBGames from "../../components/modules/section/games/KBGames.vue";
import KBVictories from "../../components/modules/section/victories/KBVictories.vue";
import KBCategories from "../../components/modules/section/categories/KBCategories.vue";
import KBPay from "../../components/modules/section/pay/KBPay.vue";
import KBLastBet from "../../components/modules/section/last-bet/KBLastBet.vue";

export default {
  name: "KBHomePage",
  updated() {
    console.log(`El componente KBHomePage se ha actualizado`);
  },

  props: {
    ads: [],
    categories: [],
    actionCards: {}
  },
  components: { KBAds, KBCategories, KBGames, KBVictories, KBPay, KBLastBet },
};
</script>

<template>
  <div>
    <KBAds :ads="ads"/>
    <KBCategories :categories="categories"/>
    <KBGames />
    <KBVictories />
    <KBPay :actionCards="actionCards"/>
    <KBLastBet />
  </div>
</template>

<style scoped lang="scss"></style>
