<script>
import store from "@/store";
import whitelabelTemplatesKBAuthModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/LoginAndRegister/store/authStoreModule";
import { computed } from "@vue/composition-api";

export default {
  name: "KBLogin",
  props: ["login_config"],
  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates-kb-auth";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(
        MODULE_APP_STORE_NAME,
        whitelabelTemplatesKBAuthModule
      );

    const showLoginDialog = computed(() => {
      return store.state["app-whitelabel-templates-kb-auth"].showLoginAuthModal;
    });

    const handleHideDialog = () => {
      store.commit("app-whitelabel-templates-kb-auth/HIDE_ALL");
    };

    return {
      showLoginDialog,
      handleHideDialog
    };
  },
  methods: {},
};
</script>

<template>
  <div class="kb-modal">
    <div
      class="modal fade"
      :class="{ show: showLoginDialog, 'd-block': showLoginDialog }"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog bg-dark kb-auth-modal-dialog">
        <div class="modal-content bg-dark kb-auth-modal">
          <button
            @click="handleHideDialog"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="kb-auth-modal-left">
            <img
              alt="register_modal_image"
              class="kb-auth-modal-left-desktop"
              :src="login_config.auth_modal_left_desktop_image"
            />
            <img
              alt="register_modal_image"
              class="kb-auth-modal-left-mobile"
              :src="login_config.auth_modal_left_mobile_image"
            />
          </div>

          <b-form class="kb-auth-modal-right">
            <div class="kb-auth-modal-right-header form-group">
              <div class="text-center text-md pb-2 text-capitalize">
                whitelabel
              </div>
              Login
            </div>

            <b-form-group label-for="username">
              <b-form-input
                id="username"
                type="text"
                placeholder="Username"
                :aria-describedby="`username-feedback`"
              ></b-form-input>
            </b-form-group>

            <b-form-group label-for="password">
              <b-input-group>
                <b-form-input
                  id="login_password"
                  :type="'text'"
                  placeholder="Password"
                  :aria-describedby="`password-feedback`"
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="outline-secondary">
                    <b-icon :icon="'eye-slash-fill'"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div class="form-group kb-auth-modal-right-forgot">
              <a>Change password</a>
            </div>

            <div class="form-group kb-auth-modal-right-submit">
              <button class="btn btn-success w-100">
                <span>Login</span>
              </button>
            </div>

            <div class="form-group kb-auth-modal-right-footer">
              New in this whitelabel
              <a>Create account</a>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: showLoginDialog }"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/auth.scss";
</style>
