export default {
    // This module is namespaced, meaning its state is isolated from other modules
    namespaced: true,
    state: {
        footer: {},
        header: {},
        clickedHeaderLink: {
            position: 0
        },
        clickedSidebarLink: {},
        clickedFooterSection: {},
        clickedCategory: {},
        clickedAdd:{},
        clickedActionCard: {},
        preview: 'general'
    },

    mutations: {
        SET_FOOTER(state, footer) {
            state.footer = footer
        },

        SET_FOOTER_SECTION(state, section) {
            state.sections = state.footer.sections.map(s => {
                if (section.id === s.id) {
                    s = section
                }
                return s;
            });
        },

        ADD_FOOTER_SECTION(state, section) {
            state.footer.push(section)
        },

        SET_CLICKED_HEADER_LINK(state, value) {
            state.clickedHeaderLink = value
        },

        SET_CLICKED_SIDEBAR_LINK(state, value) {
            state.clickedSidebarLink = value
        },

        SET_CLICKED_ADD(state, value){
            state.clickedAdd = value
        },

        SET_CLICKED_CATEGORY(state, value){
            state.clickedCategory = value
        },

        SET_CLICKED_FOOTER_SECTION(state, value) {
            state.clickedFooterSection = value
        },

        SET_CLICKED_ACTION_CARD(state, value) {
            state.clickedActionCard = value
        },

        SWITCH_PREVIEW(state, value) {
            state.preview = value
        }
    }

}
