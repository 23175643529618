export default {
  namespaced: true,
  state: {
    showLoginAuthModal: false,
    showRegisterAuthModal: false,
  },
  mutations: {
    SHOW_LOGIN_MODAL(state) {
      state.showRegisterAuthModal = false;
      state.showLoginAuthModal = true;
    },
    SHOW_REGISTER_MODAL(state) {
      state.showLoginAuthModal = false;
      state.showRegisterAuthModal = true;
    },
    HIDE_ALL(state) {
      state.showLoginAuthModal = false;
      state.showRegisterAuthModal = false;
    },
  },
  actions: {},
};
