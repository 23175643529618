<!-- eslint-disable -->
<template>
  <div class="text-white overflow-x-auto overflow-y-hidden h-14 relative mb-2">
    <div ref="lobbyBestBrands" class="flex flex-row gap-2 absolute">
      <div
        v-for="(item, index) in slides"
        class="flex items-center justify-center p-2 rounded-md h-7 w-16 text-xs"
      >
        <img style="height: 200px; width: 200px" :src="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import game_control from "../assets/svg/game-control.svg";
/* eslint-disable */

export default {
  name: "LobbyBestBrands",
  props: {
    slides: {
      type: Array,
      default() {
        return [
          { img: "/img/newLobby/img_3.png" },
          { img: "/img/newLobby/img_4.png" },
          { img: "/img/newLobby/img_5.png" },
          { img: "/img/newLobby/img_6.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
          { img: "/img/newLobby/img_7.png" },
        ];
      },
    },
  },
  data() {
    return {
      activeIndex: 0,
      arrayTest: [],
      image: game_control,
    };
  },
};
</script>

<style scoped></style>
