<script>
/* eslint-disable */
import CloseButton from "../../../components/ui/CloseButton.vue";

export default {
  name: "ApostalaSideNav",
  components: { CloseButton },
  props: ["menu"],
  directives: {
    "sidebar-color": {
      inserted: function (el) {
        el.childNodes
          .item(1)
          .style.setProperty(
            "background-color",
            "var(--background-color)",
            "important"
          );
      },
    },
  },
  data() {
    return {
      showSideBar: false,
    };
  },
  computed: {
    mainStyle() {
      return {
        "--background-color": this.menu.background_color
          ? this.menu.background_color
          : "black",
        "--background-color-hover": this.menu.background_hover_color
          ? this.menu.background_hover_color
          : "",
        "--color": this.menu.color ? this.menu.color : "white",
        "--color-hover": this.menu.hover_color
          ? this.menu.hover_color
          : "black",
        "background-color": this.menu.background_color
          ? this.menu.background_color
          : "red",
        "background-image": this.menu.background_image
          ? `url('${this.menu.background_image}')`
          : "",
        "background-size": "100% !important",
        "background-repeat": "no-repeat !important",
      };
    },
    linkStyle() {
      return {
        "font-size": this.menu.font_size ? `${this.menu.font_size}px` : "1rem",
        height: "54px",
        "text-decoration": "none",
        "text-align": "center",
        color: this.menu.color ? this.menu.color : "white",
      };
    },
  },
};
</script>

<template>
  <div class="md:hidden block order-last px-3 text-white" :style="mainStyle">
    <CloseButton v-model="showSideBar" />

    <b-sidebar
      v-model="showSideBar"
      v-sidebar-color
      right
      style="right: 220px"
      shadow
      bg-variant=""
      text-variant="white"
      @change="(newState) => (showSideBar = newState)"
    >
      <div class="w-full">
        <ul class="flex-col m-0 p-0 flex w-full">
          <li
            class="link-cont w-full"
            v-for="(link, index) in menu.links"
            :key="index"
          >
            <a
              class="link font-weight-bold px-4 py-4 m-auto text-center whitespace-nowrap inline-block"
              :href="link.href"
              :class="`link order-${link.position}`"
              :title="link.title"
              :style="linkStyle"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>

<style scoped>
.link-cont {
  width: 100%;
}
.link-cont::after {
  content: "";
  display: flex;
  width: 0;
  height: 2px;
  background: var(--color-hover);
  transition: 0.2s;
}
.link-cont:hover::after {
  width: 100%;
}
.link-cont:hover {
  transition: 0.5s;
  background-color: var(--background-color-hover);
}
.link-cont:nth-of-type(2n) {
  backdrop-filter: invert(0.08);
}
</style>
