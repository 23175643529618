<template>
  <!-- eslint-disable -->
  <div class="text-white mt-8">
    <span class="font-weight-bold">Featured</span>

    <div class="mt-3 overflow-x-auto overflow-y-hidden relative h-56">
      <div ref="lobbyRandomGames" class="flex flex-row mt-1 gap-2 absolute">
        <div
          v-for="(item, index) in 10"
          class="flex items-center justify-center rounded-md h-44 w-32"
          :style="{ backgroundColor: colorConfig.backgroundColor }"
        >
          Game {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LobbyRandomGames",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          backgroundColor: "#001a35",
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#dadada",
        };
      },
    },
  },
};
</script>

<style scoped>
/*.swiper {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.swiper-slide {*/
/*  text-align: center;*/
/*  font-size: 18px;*/
/*  background: #fff;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.swiper-slide img {*/
/*  display: block;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  object-fit: cover;*/
/*}*/
</style>
