<script>
import store from "@/store";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";

export default {
  name: "kb-footer",
  props: {
    footer: {}
  },
  updated() {
    console.log(`El componente KBFooter se ha actualizado`);
  },

  setup() {
    const MODULE_KB_STORE_NAME = "kb-store";
    // Register module
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    return {}
  },

  mounted() {
    if (this.footer) {
      this.sortSections()
      this.sortItems()
    }
  },

  computed: {
    backgroundColor() {
      return `background-color: ${this.footer.background_color || '#000'} !important;`
    }
  },

  methods: {
    editSection(item) {
      store.commit(
          "kb-store/SET_CLICKED_FOOTER_SECTION", item
      );
    },

    sortItems() {
      this.footer.sections.forEach(section => {
        const items = section.items
        items.sort((a, b) => Number(a.position) - Number(b.position));
        section.items = items
      })
    },

    sortSections() {
      const sections = this.footer.sections
      sections.sort((a, b) => Number(a.position) - Number(b.position));
      this.footer.sections = sections
    }

  }
}
</script>
<template>
  <div>
    <div v-if="footer" class="kb-footer" :style="backgroundColor">

      <div class="kb-footer-group"
           id="popover-button-disable-event"
           @click="editSection(fb, index)"
           v-for="(fb, index) in footer.sections"
           :key="index">
        <div class="kb-footer-group-title text-left">{{ fb.title }}</div>

        <div v-if="fb.type === 'links' && fb.items.length > 0" class="kb-footer-group-items">
          <div v-for="(i, index) in fb.items" :key="index" v-if="fb.items.length > 0">
            <a class="kb-footer-group-items-link">
              {{ i.text }} <span v-if="i.icon" v-html="i.icon"></span>
            </a>
          </div>
        </div>
        <div v-if="fb.type === 'networks' && fb.items.length > 0" class="kb-footer-group-networks">
          <a v-for="i in fb.items"
             :key="i._id"
             class="kb-footer-group-networks-item"
             href=""
             target="_blank"
             rel="noopener noreferrer">
            <img :src="i.image.src" :alt="i.image.alt"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins";

.kb-footer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  background-color: var(--standard-color);
  padding: 40px 10px;

  @include responsive(lg) {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(6, 1fr);
    padding: 40px 50px;
  }

  .kb-footer-group:hover {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.04);
    transition: 0.1s ease-in;
    border: 1px dashed #a13939;
    cursor: pointer;
  }

  .kb-footer-group {
    transition: 0.1s ease-out;
    border: none
  }


  &-group {
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;

    @include responsive(lg) {
      text-align: left;
    }

    &-title {
      font-size: 1rem;
      color: var(--title-color);
    }

    &-items {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0 16px;

      @include responsive(lg) {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 0;
      }

      &-link {
        text-decoration: none;
        color: var(--text-color);
        cursor: pointer;
        height: 2rem;
        font-size: .875rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          width: .875rem;
          height: .875rem;

          path {
            fill: var(--text-color);
          }
        }

        &:hover {
          color: var(--title-color);

          path {
            fill: var(--title-color);
          }
        }
      }
    }

    &-networks {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      @include responsive(lg) {
        justify-content: flex-start;
      }

      &-item {
        width: 2rem;
        height: 2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
