<template>
  <!-- eslint-disable -->
  <div class="text-white">
    <span class="font--bold" :style="{ color: colorConfig.primaryTextColor }"
      >FEATURED</span
    >

    <div class="mt-3 w-full relative h-28 overflow-x-scroll overflow-y-hidden">
      <div
        ref="lobbyFeatured"
        class="flex flex-row gap-2 overflow-hidden absolute"
      >
        <div
          v-for="(item, index) in 10"
          class="p-2 flex items-center justify-center rounded-md h-20 w-32"
          :style="{ backgroundColor: colorConfig.backgroundColor }"
        >
          Game {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  name: "LobbyFeatured",
  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          backgroundColor: "#001a35",
          shadowStyle: "0 2px 2px 0 #0277ec, 0 10px 10px 0 #1196c5",
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#dadada",
        };
      },
    },
  },
  data() {
    return {
      activeIndex: 0,
      featuresGamesInProgress: false,
    };
  },
};
</script>

<style scoped>
/*.swiper {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.swiper-slide {*/
/*  text-align: center;*/
/*  font-size: 18px;*/
/*  background: #fff;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.swiper-slide img {*/
/*  display: block;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  object-fit: cover;*/
/*}*/
</style>
