<template>
  <li class="my-px">
    <div
      href="#"
      class="flex grow flex-col gap-1 w-full py-2 px-4 ma-0 rounded-lg tres text-white"
      :style="{ backgroundColor: colorConfig.bgColor }"
    >
      <span class="font-weight-bold" :style="{ color: colorConfig.primaryTextColor }">
        {{ menuTitle.toUpperCase() }}
      </span>

      <div v-for="(item, index) in menuOptions" :key="index">
        <span> {{ item.title }} </span>
      </div>

      <div class="flex flex-col gap-1">
        <input
          class="ma-0 my-2 rounded-md w-32"
          placeholder="Search"
          v-if="type === 'brand'"
          v-model="filter"
        />
      </div>
    </div>
  </li>
</template>

<script>
/* eslint-disable */

export default {
  name: "SidebarMenu",

  data() {
    return {
      selectedItem: 1,
      filter: "",
    };
  },

  props: {
    colorConfig: {
      type: Object,
      default() {
        return {
          bgColor: "#1f93ff",
          primaryTextColor: "#ffffff",
          secondaryTextColor: "#dadada",
        };
      },
    },

    menuTitle: {
      type: String,
      default: "n/a",
    },

    menuOptions: {
      type: Array,
      required: false,
      default() {
        return [
          { title: "n/a", path: "/", icon: "" },
          { title: "n/a", path: "/", icon: "" },
        ];
      },
    },

    loading: {
      type: Boolean,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.grow {
  transition: all 0.1s cubic-bezier(0.17, 0.67, 0.88, 0.1);
}
.grow:active {
  transform: scale(0.97);
}

.v-list-item {
  min-height: 30px !important;
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-list-item__content {
  padding: 0;
}

.v-input__control {
  height: 128px !important;
}
</style>
