import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchTags(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/apostala-tags`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTag(ctx, tagData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/apostala-tags`, tagData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateTag(ctx, { id, tagData }) {
            console.log(tagData)
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/apostala-tags/${id}`, tagData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeTag(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/apostala-tags/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
