<script>
import store from "@/store";

export default {
  props: {
    actionCards: {}
  },

  name: 'KBPay',
  components: {},
  methods: {
    editItem() {
      store.commit(
          "kb-store/SET_CLICKED_ACTION_CARD", this.actionCards
      );
    },
  }
}
</script>

<template>
  <div class="kb-pay" @click="editItem" v-if="actionCards">
    <div class="kb-pay-left">
      <div class="kb-pay-left-top">
        <img v-for="(image, index ) in actionCards.left_top_images" :src="image" alt="" :key="index"/>
        <!--        <img src="../../../../assets/img/kingboxplus/a_pay.webp" alt="" />-->
        <!--        <img src="../../../../assets/img/kingboxplus/g_pay.webp" alt="" />-->
        <!--        <img src="../../../../assets/img/kingboxplus/s_pay.webp" alt="" />-->
      </div>

      <div class="kb-pay-left-bottom">
        <img v-for="(image, index ) in actionCards.left_bottom_images" :src="image" alt="" :key="index"/>
        <!--        <img src="../../../../assets/img/kingboxplus/masterpay.webp" alt="" />-->
        <!--        <img src="../../../../assets/img/kingboxplus/visa.webp" alt="" />-->
      </div>
    </div>
    <div class="kb-pay-right">
      <div class="kb-pay-right-top">
        <b>{{ actionCards.title }}</b> <br/>
        <span v-html="actionCards.subtitle"></span>
      </div>
      <div class="kb-pay-right-bottom">
        <a :href="actionCards.cta_button.title" class="btn btn-primary btn-lg">{{ actionCards.cta_button.title }}</a>
      </div>
    </div>
    <div class="kb-pay-back">
      <img v-for="(image, index ) in actionCards.back_images" :src="image" alt="" :key="index"
           :class="`kb-pay-back-ball ball-${index}`"/>
      <!--      <img-->
      <!--        class="kb-pay-back-ball ball-1"-->
      <!--        src="../../../../assets/img/kingboxplus/ball_1.png"-->
      <!--      />-->
      <!--      <img-->
      <!--        class="kb-pay-back-ball ball-2"-->
      <!--        src="../../../../assets/img/kingboxplus/ball_2.png"-->
      <!--      />-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.kb-pay:hover {
  background-color: rgba(255, 255, 255, 0.04);
  transition: 0.1s ease-in;
  border: 1px dashed rgba(161, 57, 57, 0.87);
  cursor: pointer;
}


.kb-pay {
  display: flex;
  flex-direction: column;
  background-color: var(--standard-color);
  border-radius: var(--radius);
  margin: 30px 10px;
  min-height: 30rem;
  position: relative;
  overflow: hidden;
  transition: 0.1s ease-out;

  @include responsive(lg) {
    min-height: 14.375rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 50px;
  }

  &-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-ball {
      position: absolute;

      &.ball-1 {
        width: 40%;
        top: 10%;
        left: 0;

        @include responsive(lg) {
          width: 20%;
          top: 0;
          left: 0;
          transform: translate(-20%, 0);
        }
      }

      &.ball-2 {
        width: 50%;
        top: 10%;
        right: 5%;

        @include responsive(lg) {
          width: 25%;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &-left,
  &-right {
    position: relative;
    z-index: 1;
    height: 50%;

    @include responsive(lg) {
      height: 100%;
    }
  }

  &-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.25rem;

    &-top,
    &-bottom {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    img {
      margin: 0.625rem 0;
      padding: 0 1.25rem;
      min-width: 5rem;
      max-width: 8.625rem;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
    justify-content: center;
    padding: 2.25rem;
    color: var(--title-color);
    font-size: 1.25rem;
    inset: 0;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);

    @include responsive(lg) {
      padding: 2.25rem 1.25rem 2.25rem 3.5rem;
      align-items: flex-start;
      text-align: left;
    }

    &-top {
      &-bonus {
        color: var(--yellow-color);
      }
    }
  }
}
</style>