<script>
/* eslint-disable */

import store from "@/store";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";

export default {
  props: {
    ads: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  setup() {
    const MODULE_KB_STORE_NAME = "kb-store";
    // Register module
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule)
  },

  data() {
    return {
      loadingGames: false,
      activeIndex: 0,
      swiperInstance: null,
    };
  },
  mounted() {
    this.swiperInstance = new this.$root.$swiper(this.$refs.swiperAds, {
      autoHeight: true,
      slidesPerView: 1,
      spaceBetween: 10,
      loopFillGroupWithBlank: true,
      loop: true,
      speed: 800,
      breakpoints: {
        992: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
  methods: {
    nextSlide() {
      this.swiperInstance.slideNext();
    },

    editItem(item) {
      store.commit(
          "kb-store/SET_CLICKED_ADD", item
      );
    },

    prevSlide() {
      this.swiperInstance.slidePrev();
    },
  },
};
</script>

<template>
  <div class="kb-swiper-container">
    <div class="kb-swiper-button-prev btn btn-dark" @click="prevSlide">
      <svg viewBox="0 0 32 32">
        <path
          d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
        ></path>
      </svg>
    </div>
    <div class="swiper kb-swiper" ref="swiperAds">
      <div class="swiper-wrapper" v-if="ads">
        <a  v-for="(ad, index) in ads" :key="index" class="swiper-slide">
          <img :src="ad.image" :alt="ad.name" @click="editItem(ad)"/>
        </a>

<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/2e5e7f72d0.jpg" alt="" />-->
<!--        </a>-->
<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/e64cf77cab.jpg" alt="" />-->
<!--        </a>-->
<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/0a6eac24e2.jpg" alt="" />-->
<!--        </a>-->
<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/c25ea1f0ba.jpg" alt="" />-->
<!--        </a>-->
<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/2068f0d65f.jpg" alt="" />-->
<!--        </a>-->
<!--        <a class="swiper-slide">-->
<!--          <img src="../../../../assets/img/kingboxplus/6e0ed48f5b.jpg" alt="" />-->
<!--        </a>-->
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="kb-swiper-button-next btn btn-dark" @click="nextSlide">
      <svg viewBox="0 0 32 32">
        <path
          d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.kb-swiper {
  width: calc(100vw - 20px);

  @include responsive(lg) {
    width: 100%;
  }

  &-container {
    position: relative;
    padding: 0 10px;

    @include responsive(lg) {
      padding: 0 50px;
    }

    &-casino {
      @include responsive(lg) {
        padding: 0 50px 50px 50px;
        background-color: var(--standard-color);
      }
    }
  }

  .swiper-slide {
    border-radius: var(--border-radius);
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: var(--title-color);
  }

  &-button-prev,
  &-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + 25px));
    border: none;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0.3;

    @include responsive(lg) {
      display: flex;
    }

    svg {
      width: 12px;
      height: 19px;

      path {
        fill: white;
      }
    }
  }

  &-button-prev {
    right: calc(100% - 50px);

    svg {
      rotate: -180deg;
    }
  }

  &-button-next {
    left: calc(100% - 50px);
  }

  .swiper-slide:hover {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.04);
    transition: 0.1s ease-in;
    border: 1px dashed rgba(161, 57, 57, 0.87);
    cursor: pointer;
    margin: 2px;
  }

  .swiper-slide {
    transition: 0.1s ease-out;
    border: none
  }

}
</style>
