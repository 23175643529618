<template>
  <nav class="links-container w-full" ref="greedy" :style="Style">

    <ul ref="links" style="margin:  5px" v-show="menu">
      <template v-for="(link, i) in visibleLinks">
        <li
            :class="`link-cont order-${link.position}`"
            class="link-cont"
            v-if="link.buttonType === 'submenu'"
            :key="i"
            @mouseover="dropItems = true"
            @mouseleave="dropItems = false"
        >
          <p :style="StyleLink" class="link my-0"  @click="handleSubmenu(i)">{{ link.title }}</p>
          <ul
              class="ul-drop"
              v-if="dropItems && link.buttonType === 'submenu'"
          >
            <template class="transition-all duration-500">
              <li
                  :style="StyleLink"
                  :id="`link-${link.position}`"
                  v-for="(item, j) in link.children"
                  :key="j"
                  :class="`ul-drop-item link order-${link.position}`"
                  @click="editLink([i, j])"
              >
                <a
                    :style="StyleLink"
                    class="link"
                    :title="item.title"
                >
                  {{ item.title }}
                </a>
              </li>
            </template>
          </ul>
        </li>
        <li class="link-cont" v-else :key="i">

          <a
              class="link px-4 py-3 m-auto text-center whitespace-nowrap inline-block"
              :class="'link'"
              :style="StyleLink"
              :title="link.title"
              @click="editLink(link)"
          >
            <span style="max-width: 32px" v-html="link.svgData"></span>
            {{ link.title }}
          </a>
        </li>
      </template>
    </ul>
    <div class="drop" v-show="menu && hiddenLinks.length">
      <button
          class="more-btn link-cont"
          :style="'color:' + menu.color"
          ref="greedyButton"
          @mouseover="showHiddenLinksVal = true"
          @mouseleave="showHiddenLinksVal = false"
      >
        <span
            class="px-4 py-4 capitalize text-center whitespace-nowrap inline-block"
        >
          + Juegos
        </span>
      </button>
      <ul
          :class="`absolute ${showHiddenLinksVal ? 'block' : 'd-none'}`"
          :style="hiddenLinksStyle"
          style="z-index: 200"
          class="w-full mt-11 flex items-center flex-column gap-y-2 text-center capitalize p-0 justify-center"
          ref="hiddenLinks"
          @mouseover="showHiddenLinksVal = true"
          @mouseleave="showHiddenLinksVal = false"
      >
        <li v-for="(item, index) in hiddenLinks" :key="index" class="link-cont">
          <a
              class="link w-full px-4 py-2 text-center inline-block"
              :style="StyleLink"
              :title="item.title"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import {mapState} from "vuex";
import store from "@/store";
import whitelabelTemplatesMenuModule
  from "@/views/components/whitelabel-templates/Apostala/components/common/navMenu/store/apostalaNav";


export default {
  props: ["social", "menu"],

  setup(){
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates-menu";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesMenuModule);
  },

  data() {
    return {
      dropItems: false,
      showHiddenLinksVal: false,
      visibleLinks: [], // List of visible links
      hiddenLinks: [], // List of hidden links
      showDropdown: false, // Flag to show/hide dropdown menu
    };
  },
  computed: {
    Style() {
      return {
        "--background-color": this.menu.background_color ? this.menu.background_color : "black",
        "--color-hover": this.menu.hover_color ? this.menu.hover_color : "black",
        "--color": this.menu.color ? this.menu.color : "white",
        "--background-color-hover": this.menu.background_hover_color ? this.menu.background_hover_color : "",
        "background-color": 'var(--background-color)',
        "background-image": this.menu.background_image ? `url('${this.menu.background_image}')` : "",
        "background-size": "100% !important",
        "background-repeat": "no-repeat !important",
      };
    },
    links() {
      const user = this.$store.state.userData;
      if (user) {
        const linksParsed = this.menu.links.map(link => {
          const typeLink = link.buttonType;
          const linkId = link.id;
          let linkFound = {};
          //si el suario es tipo agente se deben mostrar solo los juegos de taquillas
          user.products.forEach(product => {
            if (user.typeUser === "Player") {
              if (typeLink !== "product" && typeLink !== "lobby") {
                return link;
              } else if (
                  product.productId.toLowerCase() == linkId &&
                  product.status
              ) {
                linkFound = link;
              }
            } else if (user.typeUser === "Agent") {
              if (
                  link.buttonType === "product" ||
                  link.buttonType === "lobby"
              ) {
                if (
                    product.productId.toLowerCase() == linkId &&
                    product.status
                ) {
                  linkFound = link;
                }
              } else {
                return link;
              }
            }
            return linkFound;
          });
          return linkFound;
        });
        return linksParsed;
      } else {
        return this.menu.links;
      }
    },
    StyleLink() {
      return {
        "font-size": this.menu.font_size ? `${this.menu.font_size}px` : "1rem",
        // height: "54px",
        "text-decoration": "none",
        "text-align": "center",
        color: this.menu.color ? this.menu.color : "white",
      };
    },
    hiddenLinksStyle() {
      return {
        "font-size": this.menu.font_size ? `${this.menu.font_size}px` : "1rem",
        "background-color": this.menu.background_color
            ? this.menu.background_color
            : "red",
      };
    },

    userData() {
      return this.$store.state.userData;
    },
  },

  watch: {
    "menu.links": {
      handler() {
        this.updateLinks()
      },
      deep: true
    }
  },

  methods: {
    showHiddenLinks(val) {
      this.showHiddenLinksVal = val;
    },
    getTextWidth(title) {
      return 100;
    },
    updateLinks() {
      const nav = this.$refs.greedy;
      const pop = this.$refs.hiddenLinks
      if (!nav) return;
      // const links = this.$refs.links;
      const availableSpace = nav.clientWidth - 150;
      let totalWidth = 0;
      const visibleLinks = [];
      const hiddenLinks = [];

      pop.style.top = nav.clientTop

      // Calcular el ancho total de los enlaces
      for (const link of this.menu.links) {
        totalWidth += this.getTextWidth(link.title);
        if (totalWidth <= availableSpace) {
          visibleLinks.push(link);
        } else {
          hiddenLinks.push(link);
        }
      }

      this.visibleLinks =  this.addIndex(visibleLinks)
      this.hiddenLinks = hiddenLinks;
      this.showDropdown = hiddenLinks.length > 0;
    },


    handleSubmenu(i) {
      this.dropItems = !this.dropItems
      store.commit(
          "app-whitelabel-templates-menu/SET_CLICKED_LINK",
          [i, -1]
      );
    },
    editLink(arg){
      store.commit(
          "app-whitelabel-templates-menu/SET_CLICKED_LINK", arg
      );
    },


    addIndex(links) {
      if(links.length > 0) {
        for (const i in links) {
          links[i] = {
            ...links[i],
            index: i
          }
        }
      }

      return links
    },

    sortMenuLinks() {
      const links = this.menu.links
      if (links.length > 0) {
        links.sort((a, b) => Number(a.position) - Number(b.position));
      }
      this.menu.links = links
    }
  },
  mounted() {
    this.updateLinks();
    this.$root.$on("iframe-resized", this.updateLinks);
    this.$root.$on("highlightComponent", (e) => {
      if (e === 'Nav') {
        this.$refs.greedy.style.transform = "scale(1.01)"
        this.$refs.greedy.style.padding = "20px"
        this.$refs.greedy.style.transition = "all .3s ease-in-out"
      } else {
        this.$refs.greedy.style.transform = ""
        this.$refs.greedy.style.padding = ""
      }
    });
    this.sortMenuLinks()
  },

  beforeUnmount() {
    this.$root.$off("iframe-resized", this.updateLinks);
  },
};
</script>

<style scoped>


.link {
  font-weight: bold;
  //margin: auto;
}

.link:hover {
  transition: 0.5s;
  background-color: var(--background-color-hover);
  color: var(--color-hover) !important;
}

.link-cont {
  width: 100%;
}

.link-cont::after {
  content: "";
  display: flex;
  width: 0;
  height: 2px;
  background: var(--color-hover);
  transition: 0.2s;
}

.link-cont:hover::after {
  width: 100%;
}

.link-cont:hover {
  transition: 0.5s;
  background-color: var(--background-color-hover);
}

.links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
}

.links-container ul {
  display: flex;
  margin: 0;
  align-items: center;
}

.ba {
  z-index: 99;
}

.drop {
  position: relative;
  display: inline-block;
}

.ul-drop {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.ul-drop-item {
  background-color: var(--background-color);
  list-style: none;
  width: 100%;
}

.more-btn {
  background: var(--background-color);
  border: none;
  min-width: 7rem;
  font-weight: bold;
  cursor: pointer;
  color: var(--t-color);
}
</style>
