<template>
  <div class="nav-container">
    <nav ref="nav" class="nav">
      <ul class="menu-list d-flex flex-column">
        <li
          v-for="(data, index) in menu"
          :key="index"
          class="menu-item d-flex justify-content-center w-100 nav-icon"
          :id="`nav-${data.window}`"
          @click="handleMenuWindow(data.window)"
        >
          <feather-icon :icon="data.icon" size="15" class="menu-icon" />
          <b-popover
            v-if="selectedMenuWindow !== data.window"
            :target="`nav-${data.window}`"
            triggers="hover"
            :delay="200"
          >
            <span>{{ data.name }}</span>
          </b-popover>
        </li>
      </ul>
    </nav>

    <!-- Close button -->
    <div ref="closeBtn" class="close-btn-container">
      <ul class="close-button flex-column">
        <li @click="toggleSidebar">
          <feather-icon
            :icon="closed ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="14"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateNavMenu",
  props: {
    menu: {
      type: Array,
      required: true,
    },
    selectedMenuWindow: {
      type: String,
      default: "",
    },
  },
  data(){
    return{
      closed: false
    }
  },
  methods: {
    onClickSideBar() {
      this.closed = !this.closed;
      this.$refs.nav.style.left = this.closed ? "-70px" : "0px";
      this.$refs.nav.style.transform = this.closed ? "scale(0.95)" : "scale(1)";
      this.$refs.closeBtn.style.left = this.closed ? "-50px" : "25px";
    },

    handleMenuWindow(window) {
      this.$emit("menu-window-selected", window);
    },

    toggleSidebar() {
      this.onClickSideBar()
      this.$emit("toggle-sidebar", this.closed);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-container {
  transition: all 0.2s ease;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  .nav {
    position: absolute;
    height: 100%;
    width: 80px;
    top: 0;
    left: 0;
    transition: all 0.4s ease;
  }

  .menu-list {
    padding: 15px;
    gap: 10px;
    width: 70px;
  }

  .menu-item {
    padding: 12px 8px;
    border-radius: 10px;
    background-color: white;
  }

  .menu-icon {
    color: black;
  }

  .close-btn-container {
    position: absolute;
    height: 80px;
    top: 115px;
    left: 25px;
    transition: all 0.5s ease-out;
  }

  .close-button li {
    background: #283045;
    text-align: center;
    height: 35px;
    display: flex;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: all 0.5s linear;
  }
}
</style>
