<template>
  <div>
    <push-button
        label="Ingresar"
        show-icon
        :color="config.color || '#fff'"
        :backgroundColor="config.background_color || '#000'"
    />
  </div>
</template>

<script>
/*eslint-disable*/
import PushButton from "../../../ui/PushButton.vue";
export default {
  name: "ApostalaLogin",
  props:['config'],
  components: { PushButton },
  data() {
    return {
      dialog: false
    };
  },
};
</script>
<style scoped>
.v-dialog__content {
  align-items: flex-start;
  //justify-content: flex-end;
}
</style>
