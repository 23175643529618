<template>
  <svg v-bind="svgAttributes" v-html="svgContent"></svg>
</template>

<script>
export default {
  name: "InlineSvg",
  props: {
    svgData: {
      type: String,
      required: true,
    },
  },
  computed: {
    svgAttributes() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(this.svgData, "image/svg+xml");
      const svgNode = svgDocument.documentElement;

      // Create an object with the attributes
      const attributes = {};
      Array.from(svgNode.attributes).forEach(attr => {
        attributes[attr.name] = attr.value;
      });

      return attributes;
    },
    svgContent() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(this.svgData, "image/svg+xml");
      const svgNode = svgDocument.documentElement;

      return svgNode.innerHTML;
    },
  },
};
</script>
