<template>
  <div>
    <!-- === COLLAPSE TAGS MENU === -->
    <div
        v-if="data.tags_menu"
        :class="collapse.tags_menu ? '' : 'border-bottom border-gray'"
    >
      <div
          @click="collapse.tags_menu = !collapse.tags_menu"
          class="media py-2"
          :aria-expanded="collapse.tags_menu ? 'true' : 'false'"
          aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
            class="d-flex justify-content-between w-100"
            style="padding-top: 10px"
        >
          <h5>
            {{ $t("tags_menu") }}
          </h5>

          <div>
            <feather-icon
                size="20"
                :icon="collapse.tags_menu ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-tags-menu" v-model="collapse.tags_menu">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.backgroundcolor") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'tags_menu.background_color')"
                    :value="data.tags_menu.background_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'tags_menu.primary_text_color')"
                    :value="data.tags_menu.primary_text_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'tags_menu.secondary_text_color')"
                    :value="data.tags_menu.secondary_text_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === BRANDS MENU === -->
    <div
        v-if="data.brands_menu"
        :class="collapse.brands_menu ? '' : 'border-bottom border-gray'"
    >
      <div
          @click="collapse.brands_menu = !collapse.brands_menu"
          class="media py-2"
          :aria-expanded="collapse.brands_menu ? 'true' : 'false'"
          aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
            class="d-flex justify-content-between w-100"
            style="margin-top: 10px"
        >
          <h5>
            {{ $t("brands_menu") }}
          </h5>

          <div>
            <feather-icon
                size="20"
                :icon="collapse.brands_menu ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-brands_menu" v-model="collapse.brands_menu">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.background-color") }}</h5>
                <b-form-input
                    type="color"
                    @change="
                    onColorChange($event, 'brands_menu.background_color')
                  "
                    :value="data.brands_menu.background_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("text_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'brands_menu.primary_text_color')"
                    :value="data.brands_menu.primary_text_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'brands_menu.secondary_text_color')"
                    :value="data.brands_menu.secondary_text_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

        </b-list-group>
      </b-collapse>
    </div>

    <div
        v-if="data.others"
        :class="collapse.others ? '' : 'border-bottom border-gray'"
    >
      <div
          @click="collapse.others = !collapse.others"
          class="media py-2"
          :aria-expanded="collapse.others ? 'true' : 'false'"
          aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
            class="d-flex justify-content-between w-100"
            style="margin-top: 10px"
        >
          <h5>
            {{ $t("Others") }}
          </h5>

          <div>
            <feather-icon
                size="20"
                :icon="collapse.others ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-others" v-model="collapse.others">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="
                    onColorChange($event, 'others.primary_color')
                  "
                    :value="data.others.primary_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("title_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'others.title_color')"
                    :value="data.others.title_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("game_text_color") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'others.game_text_color')"
                    :value="data.others.game_text_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

        </b-list-group>
      </b-collapse>
    </div>

    <!-- === PREVIEW === -->
<!--    <b-col v-if="data">-->
<!--      <b-card>-->
<!--        <b-row cols="1" cols-sm="2">-->
<!--          <b-col sm="4" tag="h5" class="text-left ma-auto">{{-->
<!--              $t("tooltips.preview")-->
<!--            }}</b-col>-->
<!--          <b-col sm="12" md="8" class="text-md-right px-0">-->
<!--            <b-button-->
<!--                size="sm"-->
<!--                class="ml-1 text-capitalize"-->
<!--                :variant="size === breackpoint ? 'primary' : 'link'"-->
<!--                v-for="(breackpoint, i) in sizes"-->
<!--                @click="size = breackpoint"-->
<!--                :key="i"-->
<!--            >-->
<!--              <b-icon-->
<!--                  class="d-none d-sm-inline mr-icon"-->
<!--                  style="margin-left: 0.3em"-->
<!--                  :icon="breackpoint"-->
<!--              ></b-icon>-->
<!--              {{ breackpoint }}-->
<!--            </b-button>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-card>-->

<!--      <b-card class="bg-transparent">-->
<!--        <lobby-preview :lobby="data" :size="size" />-->
<!--      </b-card>-->
<!--    </b-col>-->
  </div>
</template>

<script>
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { set, sortBy, cloneDeep } from "lodash";
import LobbyPreview from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-preview/LobbyPreview.vue";

export default {
  name: "LobbySpecificForm",
  components: {
    LobbyPreview,
    ImageFieldForm,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    propData: {
      type: Object,
      required: true,
    },
    collapse: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      data: { ...this.propData },
      sizes: ["phone", "tablet", "laptop"],
      size: "phone",
    };
  },

  methods: {
    updateParent() {
      this.$emit("update:data", this.data);
    },

    loadLobbyImages() {
      this.$emit("loadLobbyImages");
    },

    errorInLoadImage(error) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    setPreviewImage(e) {
      // console.log(e);
    },

    onColorChange(event, target) {
      // this.data[target] = event;
      set(this.data, target, event);
      // console.log(target);
      // console.log(this.data[target]);
      // console.log(this.data.tags_menu.background_color);
      this.updateParent();
    },

    async onChangeImage(value, target) {
      if (value === "reload" && this.images?.routes?.length) {
        this.loadLobbyImages();
      } else if (value === "error") console.log("error");
      else {
        set(this.data, target, value);
        this.updateParent();
      }
      //Lodash's _.set() method is used to set the value of a property on an object in a nested manner.
    },

    onUploadedImage(data, target) {
      if (data?.files.length) {
        set(this.data, target, data.files[data.files.length - 1]);
        this.updateParent();
      }
    },
  },

  watch: {
    propData: {
      handler(newVal) {
        this.data = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
