export default {
    namespaced: true,
    state: {
        link: null
    },
    mutations: {
        SET_CLICKED_LINK(state, value) {
            state.link = value;
        },
    },
    actions: {},
};
