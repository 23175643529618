<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import { svgs } from "@/views/components/whitelabel-templates/Apostala/assets/svgs";
import formValidation from "@core/comp-functions/forms/form-validation";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "ItemForm",
  components: {
    ImageFieldForm,
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({
        href: '',
        title: '',
        image: ''
      }),
    },
  },
  setup(props, { emit }) {
    const formValid = ref(false);
    const toast = useToast();

    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const onCancel = () => {
      emit("reset");
    };

    const itemButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };

    const onClearImage = () => {
      props.item.image = ''
      emit('clear')
    }


    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        props.item.image = image || "";
        emit('uploaded')
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };


    const { refItemFormObserver, getValidationState, resetForm } =
        formValidation(() => {});

    return {
      formValid,
      onUploadedImage,
      errorInLoadImage,
      onCancel,
      refItemFormObserver,
      getValidationState,
      resetForm,
      onClearImage,
      onSubmit,
    };
  },
};
</script>

<template>
  <validation-observer
      ref="refItemFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

      <b-list-group-item style="background: none" class="px-0">
        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="href"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="href" label-for="href">
                <template #label>{{ $t("labels.link") }}</template>
                <b-form-input
                    id="href"
                    type="text"
                    v-model="item.href"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="href"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="title" label-for="title">
                <template #label>{{ $t("title") }}</template>
                <b-form-input
                    id="title"
                    type="text"
                    v-model="item.title"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span>{{ $t('labels.image') }}</span> <br/>
            <span>1500x400</span>
            <image-field-form
                :single-button="true"
                :text="$t('labels.image')"
                class="w-100 mt-1"
                :value="item.image"
                size-validation="1500x400"
                path="carousel/images"
                @uploadedImage="onUploadedImage($event)"
                @error="errorInLoadImage"
                preview="true"
            >
            </image-field-form>

            <div v-if="item.image" class="w-100">
              <img
                  style="max-width: 100%"
                  :src="item.image"
                  alt="Uploaded Image"
                  class="mt-1 rounded"
              />
              <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary" size="sm">
                X
              </b-button>
            </div>
          </b-col>
        </b-row>


      </b-list-group-item>

      <div class="mt-4 d-flex flex-row" style="gap: 5px">
        <b-button
            :disabled="invalid"
            type="submit"
            variant="primary"
        >
          {{
            settings.action === "edit"
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary">{{
            $t("buttons.cancel")
          }}</b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
  margin: 5px
}
</style>
