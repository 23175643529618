<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import vSelect from "vue-select";
import LinkList from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/MenuManagement/linkList.vue";
import LinkForm from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/MenuManagement/linkForm.vue";
import i18n from "@/libs/i18n";
import apStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import menuStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/MenuManagement/store/menuStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "index",
  components: {
    LinkForm,
    LinkList,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "menu",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const MENU_AP_STORE_MODULE_NAME = "ap-menu-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(MENU_AP_STORE_MODULE_NAME))
      store.registerModule(MENU_AP_STORE_MODULE_NAME, menuStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, apStoreModule);

    const menu = ref({
      color: '',
      name: '',
      background_color: '',
      hover_color: '',
      font_size: '',
      background_image: '',
      links: []
    });

    const loading = ref(false);
    const settings = ref({
      status: "list", // 'list' | 'form'
      action: "edit", // 'edit' | 'add'
    });
    const link = ref({
      href: "",
      title: "",
      buttonType: "link",
      children: [],
      svgData: "",
      color: "",
      position: ''
    });

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();
    const toast = useToast();

    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetMenu();
      const {itemsSelected} = props.options;
      if (itemsSelected) menu.value = itemsSelected;
      updatePreview();
    });

    const resetMenu = () => {
      menu.value = {
        color: '',
        name: '',
        background_color: '',
        hover_color: '',
        font_size: '',
        background_image: '',
        links: []
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          menu: value || menu.value,
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.menu'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateMenu();
      } else {
        createMenu();
      }
    };

    const updateMenu = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            "ap-menu-store/updateMenu",
            {
              id: menu.value._id,
              menuData: rBuilder.clean(menu.value),
            }
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createMenu = async () => {
      loading.value = true;
      menu.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      menu.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "ap-menu-store/addMenu",
            rBuilder.clean(menu.value)
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "template.menu");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      resetAll();
      emit("reset");
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const showForm = () => {
      settings.value.status = "form";
    };

    const enableEditMode = () => {
      settings.value.action = "edit";
    };

    const enableAddMode = () => {
      settings.value.action = "add";
    };

    const resetLink = () => {
      link.value = {
        href: "",
        title: "",
        buttonType: "link",
        children: [],
        svgData: "",
        color: "",
      };
    };

    const onEditLink = (value) => {
      showForm();
      enableEditMode();
      link.value = value;
    };

    const onAddLink = () => {
      showForm();
      enableAddMode();
      resetLink();
      updatePreview();
    };

    const onDeleteLink = (id) => {
      menu.value.links = menu.value.links.filter(
          (item) => item._id !== id
      );
      updatePreview();
    };

    const showList = () => {
      settings.value.status = "list";
    };

    const resetAll = () => {
      resetLink();
      showList();
      store.commit("ap-store/SET_CLICKED_MENU_LINK", null);
      // updatePreview();
    };


    const saveLink = () => {
      link.value = {
        _id: uuidGen.generate(),
        ...link.value,
      };
      menu.value.links.push(link.value);
      updatePreview();
      showList();
      resetAll();
      sortMenuLinks();
    };

    const sortMenuLinks = () => {
      const links = menu.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      menu.value.links = links;
    };

    const updateLink = () => {
      menu.value.links = menu.value.links.map((item) => {
        if (item._id === link.value._id) {
          return link.value;
        }
        return item;
      });
      updatePreview();
      showList();
      resetAll();
      sortMenuLinks();
    };

    const editLinkHandler = (val) => {
      const exist =
          menu.value.links.find((item) => item._id === val._id) !== undefined;
      if (exist) {
        link.value = val;
        enableEditMode();
        showForm();
      } else {
        resetLink();
        showList();
      }
      updatePreview();
    };

    const onUploadedImage = (data, value) => {

      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        menu.value.background_image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onClearImage = (key) => {
      menu.value[key] = ''
      updatePreview();
    }


    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditLink,
      onAddLink,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteLink,
      loading,
      resetAll,
      menu,
      saveLink,
      updateLink,
      link,
      editLinkHandler,
      updatePreview,
      onUploadedImage,
      errorInLoadImage,
      onClearImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="menu"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="s-name"
                      type="text"
                      v-model="menu.name"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.color") }}</template>
                  <b-input
                      type="color"
                      id="color"
                      v-model="menu.color"
                      @input="updatePreview()"
                      label="color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.background-color") }}</template>
                  <b-input
                      type="color"
                      id="background_color"
                      v-model="menu.background_color"
                      @input="updatePreview()"
                      label="background_color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="hover_color" label-for="hover_color">
                  <template #label>{{ $t("labels.hovercolor") }}</template>
                  <b-input
                      type="color"
                      id="background_color"
                      v-model="menu.hover_color"
                      @input="updatePreview()"
                      label="hover_color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <span>{{ $t('labels.backgroundimage') }}</span> <br/>
              <span>1280x300</span>
              <image-field-form
                  :single-button="true"
                  :text="$t('labels.backgroundimage')"
                  class="w-100 my-1"
                  :value="menu.background_image"
                  size-validation="1280x300"
                  path="menus"
                  @uploadedImage="onUploadedImage($event, 'background_image')"
                  @error="errorInLoadImage"
                  preview="true"
              >
              </image-field-form>

              <div v-if="menu.background_image" class="w-100">
                <img
                    style="max-width: 100%"
                    :src="menu.background_image"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />
                <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary" size="sm">
                  X
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="fontSize"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="With" label-for="fontSize">
                  <template #label>{{ $t("font_size") }}</template>
                  <b-form-input
                      id="fontSize"
                      type="number"
                      @input="updatePreview()"
                      v-model="menu.font_size"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

        </b-tab>

        <b-tab title="Settings">
          <link-list
              :links="menu.links"
              v-if="settings.status === 'list'"
              @add="onAddLink()"
              @edit="onEditLink($event)"
              @delete="onDeleteLink($event)"
          >
          </link-list>
          <link-form
              class="mt-1"
              @reset="resetAll()"
              @save="saveLink()"
              @update="updateLink()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :link="link"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </link-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
