var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"gridGamesWrapper",staticClass:"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-4 gap-8",on:{"click":function($event){return _vm.onclick()}}},_vm._l((_vm.brandList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"flex flex-row justify-between items-center pt-2 px-1 pb-2"},[_c('span',{staticClass:"font-weight-bold text-truncate mr-2"},[_vm._v(_vm._s(item.brandName.toUpperCase()))]),_c('button',{staticClass:"text-white px-3 py-1 rounded shadow-none font--extra-bold",class:[_vm.configColor.buttonClass || ''],staticStyle:{"font-size":"10px"},style:(_vm.configColor.buttonStyle)},[_vm._v(" See all ")])]),(item.gamesPreview.length >= 3)?_c('div',{staticClass:"flex flex-row flex-wrap h-72 gap-4"},_vm._l((item.gamesPreview),function(item,index){return _c('div',{key:index,staticClass:"h-1/2 w-100 rounded-l-lg rounded-tr-3xl rounded-br-lg",class:[index === 0 ? 'w-full' : 'flex-1'],staticStyle:{"border-width":"3px","max-width":"100%","overflow":"hidden"},style:({ borderColor: _vm.configColor.borderColor })},[_c('div',{staticClass:"flex flex-row items-end h-100 w-100 gap-1 relative",staticStyle:{"height":"100%"}},[_c('img',{staticClass:"object-cover bg-no-repeat bg-contain transform hover:scale-105 transition duration-500",style:({
              background: _vm.configColor.bgColor,
              width: '100%',
              height: '100%',
            }),attrs:{"alt":"Game Image","src":item.url_image}}),_c('section',{staticClass:"absolute right-2 rounded-full bg-pink-700",class:[index === 0 ? 'bottom-2 p-2' : 'top-2 pa-1'],staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"text-center text-xs",style:(index === 0 ? 'font-size: 16px' : 'font-size: 14px')},[_vm._v("mdi-heart")])]),_c('div',{staticClass:"absolute flex flex-row gap-1 items-center p-1"},[_c('section',{staticClass:"flex flex-col overflow-hidden"},[_c('span',{staticClass:"leading-3 text-sm truncate pt-1"},[_vm._v(_vm._s(item.gameName || ""))]),_c('span',{staticClass:"font--light truncate",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.brandName || ""))])])])])])}),0):(item.gamesPreview.length > 0)?_c('div',{staticClass:"flex flex-row flex-wrap h-72 gap-4"},[_c('div',{staticClass:"w-100 rounded-l-lg rounded-tr-3xl rounded-br-lg",staticStyle:{"border-width":"3px","max-width":"100%","overflow":"hidden"},style:({ borderColor: _vm.configColor.borderColor })},[_c('div',{staticClass:"flex flex-row items-end gap-1",staticStyle:{"height":"100%"}},[_c('img',{staticClass:"object-cover bg-no-repeat bg-contain h-100 w-100 transform hover:scale-105 transition duration-500",style:({ background: _vm.configColor.bgColor, height: '100%' }),attrs:{"alt":"Game Image","src":item.gamesPreview[0].url_image}}),_c('div',{staticClass:"absolute flex flex-row gap-1 items-center p-2"},[_c('section',{staticClass:"flex flex-col"},[_c('span',{staticClass:"leading-3 text-sm pt-1"},[_vm._v(_vm._s(item.gameName || ""))]),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.brandName || ""))])])])])])]):_c('div',{staticClass:"flex flex-row flex-wrap h-72 gap-4"},[_c('div',{staticClass:"h-100 rounded-l-lg rounded-tr-3xl rounded-br-lg bg-gray-500 text-black flex justify-center items-center",staticStyle:{"border-width":"3px","max-width":"100%","overflow":"hidden"},style:({
          width: '100%',
          background: _vm.configColor.bgColor,
          borderColor: _vm.configColor.borderColor,
        })},[_c('span',{staticClass:"text-3xl font--light"},[_vm._v("Empty...")])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }