<template>
  <footer class="footer px-4 py-6">
    <div class="footer-content">
      <p class="text-sm text-center">Footer</p>
    </div>
  </footer>

</template>

<script>
export default {
  name: 'LobbyFooter'
}
</script>

<style scoped>

</style>