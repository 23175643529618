<script>
import i18n from '@/libs/i18n'
import store from "@/store";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";

export default {
  props: {
    header: {}
  },
  updated() {
    console.log(`El componente KBHeader se ha actualizado`);
  },


  setup() {
    const MODULE_KB_STORE_NAME = "kb-store";
    // Register module
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);


    return {}
  },
  data() {
    return {
      dropdownLang: false,
      locales: [
        {
          locale: "es",
          img: "/img/flags/Spain.svg",
          name: "Español",
        },
        {
          locale: "en",
          img: "/img/flags/USA.svg",
          name: "English",
        },
      ],
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === i18n.locale);
    },

    fontSize() {
      let style = ''
      if (this.header.font_size) {
        style = 'font-size: ' + this.header.font_size + ' !important'
      }
      return style
    }
  },
  methods: {
    handleSetLocale(locale) {
      i18n.locale = locale
      localStorage.setItem('lang', locale)
    },
    parseSVG(svgData) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(svgData, 'image/svg+xml');
      const svgNode = svgDocument.documentElement;
      return svgNode.innerHTML
    },

    setHover($event, color) {
      const element = $event.target
      if ($event.type === 'mouseenter') {
        element.style = "background: " + color + " !important"
      } else {
        element.style = ""
      }
    },

    editLink(link) {
      store.commit(
          "kb-store/SET_CLICKED_HEADER_LINK", link
      );
    },

    sortLinks() {
      const links = this.header.links
      if (links.length > 0) {
        links.sort((a, b) => Number(a.position) - Number(b.position));
      }
      this.header.links = links
    }
  },

  mounted() {
    this.sortLinks()
  }
}
</script>

<template>
  <div class="kb-header">
    <div class="kb-header-left show">
      <a
        v-for="(link, index) in header.links"
        @mouseenter="setHover($event, link.hoverColor)"
        @mouseleave="setHover($event, null)"
        @click="editLink(link)"
        :key="index"
        href="javascript:void(0)"
        class="btn"
      >
        <svg viewBox="0 0 32 32" v-html="parseSVG(link.svgData)"></svg>
        {{ link.title }}
      </a>
    </div>

    <div class="kb-header-right">
      <div class="dropdown">
        <button
          @click="dropdownLang = !dropdownLang"
          class="btn"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <svg viewBox="0 0 32 32">
            <path
              d="M16 2c-7.732 0-14 6.268-14 14s6.268 14 14 14c7.732 0 14-6.268 14-14s-6.268-14-14-14v0zM26.695 11.334c0.624 1.429 0.973 3.007 0.973 4.667s-0.348 3.238-0.973 4.667h-5.261c0.257-1.447 0.398-3.021 0.398-4.667s-0.14-3.22-0.398-4.667h5.261zM12.502 16.001c0-1.676 0.129-3.262 0.362-4.667h6.269c0.234 1.405 0.365 2.991 0.365 4.667s-0.131 3.262-0.365 4.667h-6.266c-0.234-1.405-0.365-2.991-0.365-4.667v0zM25.334 9.001h-4.443c-0.542-1.821-1.286-3.32-2.163-4.344 2.64 0.636 4.976 2.172 6.605 4.344v0zM16 5.503c1.036 0 1.969 1.354 2.61 3.5h-5.219c0.641-2.149 1.574-3.5 2.61-3.5zM13.271 4.659c-0.877 1.022-1.62 2.525-2.161 4.344h-4.443c1.629-2.172 3.964-3.708 6.603-4.344v0zM5.305 11.334h5.261c-0.257 1.447-0.398 3.021-0.398 4.667s0.14 3.22 0.398 4.667h-5.261c-0.643-1.472-0.974-3.061-0.973-4.667 0-1.66 0.348-3.238 0.973-4.667v0zM6.666 23.002h4.443c0.542 1.821 1.286 3.32 2.163 4.344-2.64-0.636-4.976-2.172-6.605-4.344zM16 26.5c-1.036 0-1.969-1.354-2.61-3.5h5.219c-0.645 2.149-1.574 3.5-2.61 3.5zM18.729 27.344c0.877-1.022 1.62-2.525 2.161-4.344h4.443c-1.629 2.172-3.964 3.708-6.603 4.344v0z"
            ></path>
          </svg>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-menu-dark"
          :class="{ 'show': dropdownLang }"
        >
          <h6 class="dropdown-header">Idioma</h6>
          <a
            v-for="(lProps, index) in locales"
            :key="index"
            @click="handleSetLocale(lProps.locale)"
            class="dropdown-item"
            :class="[{'active': lProps.locale === currentLocale.locale}]"
          >
            {{ lProps.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins";

.kb-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: var(--header-height);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--layout-header-bg);
  max-width: 100%;

  @include responsive(lg) {
    box-shadow: 0 0 15px color-mix(in srgb, var(--shadow-color) 70%, transparent 100%);
    justify-content: space-between;
    padding: 0 50px;
    position: static;
    overflow: visible;
  }



  &-left {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    padding: 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transform: translateY(-100%);
    transition: var(--transition);

    &::-webkit-scrollbar {
      display: none;
    }

    @include responsive(lg) {
      background: none;
      padding: 0;
      position: static;
      width: auto;
      overflow: visible;
      top: auto;
      left: auto;
    }

    &.show {
      transform: translateY(0);
    }

    @include btn;

    .btn {
      background-color: var(--layout-header-btn-bg);
      color: var(--layout-header-btn-color);
      fill: var(--layout-header-btn-color) !important;

      &:hover {
        background-color: var(--layout-header-btn-bg-hover);
      }
    }
  }

  &-right {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 15px color-mix(in srgb, var(--shadow-color) 70%, transparent 100%);

    @include responsive(lg) {
      box-shadow: none;
      width: auto;
      height: auto;
    }

    .dropdown {
      .btn {
        background-color: var(--layout-header-btn-bg);
        color: var(--layout-header-btn-color);
        fill: var(--layout-header-btn-color) !important;

        &:hover {
          background-color: var(--layout-header-btn-bg-hover);
        }
      }

      &-menu {
        background-color: var(--standard-color);
      }

      &-item {
        color: var(--text-color);
        cursor: pointer;

        &:hover {
          background-color: var(--background-color);
        }

        &.active,
        &:active {
          background-color: var(--yellow-color);
          color: var(--standard-color);
        }
      }
    }
  }
}
</style>
