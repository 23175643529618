<template>
  <header class="header">
    <div class="w-full bg-cover" :style="headerStyle">
      <div
          :class="[
          'grid',
          `grid-cols-${headerCols}`,
          'gap-1',
          'align-center',
          'px-2',
        ]"
      >
        <section v-if="showLogo" class="justify-self-start" style="display: flex !important; align-items: center">
          <img
              class="h-auto"
              width="150"
              alt="logo"
              style="max-height: 30px"
              :src="header.logo.href"
          />
        </section>

        <section class="header-nav col-span-3">
          <apostala-nav v-if="modifiedMenu" :menu="modifiedMenu" :key="appNavKey"/>
        </section>

        <section
            class="col-span-4 py-2 header-auth justify-self-end md:col-span-1"
        >
          <div class="flex-row d-flex align-items-center w-100">
            <div
                v-if="!userData"
                class="flex items-center gap-2 justify-end pr-2 text-white w-100 container-main-buttons"
            >

              <apostala-whatsapp-button :header="header"/>

              <apostala-register
                  v-if="!header.auth.hide"
                  :config="header.auth"
              />

              <apostala-login
                  v-if="!header.auth.hide"
                  :config="header.auth"
                  ref="userLogin"
              />


            </div>

            <div class="flex justify-center" v-else>
              <!-- <user-menu :config="header.auth" :user="userData"></user-menu>-->
            </div>

            <apostala-side-nav v-if="menu" :menu="modifiedMenu"/>
          </div>
        </section>
      </div>
    </div>
  </header>
</template>
<script>
/* eslint-disable */
import ApostalaLoginButton from "../../modules/auth/login/ApostalaLogin.vue";
import ApostalaNav from "../navMenu/ApostalaNav.vue";
import ApostalaSideNav from "../navMenu/ApostalaSideNav.vue";
import Button from "@/views/components/button/Button.vue";
import ApostalaWhatsappButton
  from "@/views/components/whitelabel-templates/Apostala/components/common/headers/ApostalaWhasappButton.vue";
import ApostalaRegister
  from "@/views/components/whitelabel-templates/Apostala/components/common/headers/ApostalaRegister.vue";
import ApostalaLogin
  from "@/views/components/whitelabel-templates/Apostala/components/common/headers/ApostalaLogin.vue";

export default {
  components: {
    ApostalaLogin,
    ApostalaRegister,
    ApostalaWhatsappButton,
    Button,
    ApostalaSideNav,
    ApostalaNav,
    ApostalaLoginButton,
  },
  props: ["header", "menu", "appNavKey"],
  data() {
    return {
      userData: false,
      API: process.env.VUE_APP_RUTA_API,
    };
  },
  computed: {
    showLogo() {
      return this.header.logo && !this.header.logo.hide;
    },

    headerStyle() {
      return {
        backgroundImage: this.header.background_image
            ? `url('${this.header.background_image}')`
            : "",
        backgroundColor: this.header.background_color || "",
        "--logo-w": (this.header.logo?.width || 100) + "px",
        "--logo-h": (this.header.logo?.height || "") + "px",
        maxHeight: "280px",
        overflow: "hidden"
      };
    },

    headerCols() {
      let cols = 5;
      if (this.header.logo?.hide) cols--;
      if (this.header.hide) cols--;
      return cols;
    },

    modifiedMenu() {
      return {
        ...this.menu,
        background_hover_color: "#262626",
        active_background_color: "#E8517E",
        active_color: "#E8517E",
      };
    },

    lastLoginDate() {
      const lastLoginDate = this.userData?.userSession?.lastLogin;
      return lastLoginDate
          ? moment(lastLoginDate).format("DD/MM/YYYY HH:mm:ss")
          : "";
    },
  },
  methods: {},
  mounted() {
    this.$root.$on("highlightComponent", (e) => {
      if (e === "Header") {
        this.$refs.header.style.transform = "scale(1.01)";
        this.$refs.header.style.transition = "all .3s ease-in-out";
      } else {
        this.$refs.header.style.transform = "";
      }
    });
  },


};
</script>
<style scoped lang="scss">
@import "../../../../common/assets/mixins";

.header {
  &-container {
    height: 15rem;
    width: 100%;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  &-auth {
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(lg) {
      font-size: 0.8rem;
    }

    &-login {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      gap: 0.5rem;

      @include responsive(md) {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }

    &-separator {
      width: 1px;
      height: 0.8rem;
      background-color: white; //var(--header-c);
      display: none;

      @include responsive(md) {
        display: block;
      }
    }
  }

  &-logo {
    width: 70%;
    align-items: center;
    justify-content: center;
  }

  &-auth,
  &-logo {
    @include responsive(lg) {
      width: fit-content;
    }
  }

  .container-main-buttons {
    @include responsive(down-sm) {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
    }
  }


  @for $i from 1 through 5 {
    .order-#{$i} {
      order: $i;
    }
  }
}

.header-nav {
  display: none;
}

@media (min-width: 780px) {
  .header-nav {
    display: flex;
    align-items: center;
    max-height: 70px;
  }
}
</style>
