<script>
import TagsButton from "@/views/components/whitelabel-templates/Apostala/components/ui/TagsButton.vue";

export default {
  name: "ApostalaMoreProducts",
  components: {TagsButton},
  props: ['products'],

  computed: {
    screenSm() {
      return window.width <= 340;
    }
  },

  data() {
    return {
    }
  },
};
</script>

<template>
  <section
      class="flex justify-between w-full gap-10 place-items-center h-96 pa-5"
  >
    <div
        v-for="(item, index) in products"
        :key="index"
        class="relative flex items-end justify-center h-full overflow-hidden rounded-md group w-60"
    >
      <img
          alt="product-image"
          class="absolute object-cover w-full transition duration-500 transform h-100 group-hover:scale-105"
          :src="(screenSm ? item.imageMobile : item.imageDesktop) || item.image"
      />
      <TagsButton :color="item.color"
                  :text="item.text"
                  :textColor="item.text_color"
                  class="mb-10 transform group-hover:scale-110"
                  style="z-index: 4"
      />
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
