var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row min-h-screen w-100 bg-gray-100 text-gray-800 hero-image relative",style:({
    width: '100vw',
    backgroundImage: ("url(" + (this.lobby.header_section.third_img) + ")"),
    backgroundColor: _vm.lobby.background_color,
  })},[_c('img',{staticClass:"absolute bottom-0 footer_image",attrs:{"alt":"","src":this.lobby.footer_section.primary_img,"width":"100px","height":"100px"}}),_c('div',{staticClass:"absolute w-100 h-52 bottom-0 right-0 left-0 bg-black"},[_c('img',{staticStyle:{"object-fit":"cover","height":"100%","width":"100%"},attrs:{"src":this.lobby.footer_section.primary_img,"alt":"main_image"}})]),_c('aside',{staticClass:"sidebar md:shadow transform md:translate-x-50 transition-transform duration-150 ease-in",class:[_vm.showSideBar ? 'show' : ''],style:({ backgroundColor: _vm.lobby.background_color }),attrs:{"id":"navigation-menu"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"sidebar-content px-2 py-2"},[_c('ul',{staticClass:"flex flex-col w-full pa-0 gap-1"},[_c('SidebarActivator',{attrs:{"menu-options":this.lobby.main_menu.menu_options,"color-config":{
            bgColor: this.lobby.main_menu.background_color,
            primaryTextColor: this.lobby.main_menu.primary_text_color,
            secondaryTextColor: this.lobby.main_menu.secondary_text_color,
          }},on:{"showHide":function($event){return _vm.onClickMenu()}}}),_c('SidebarMenu',{attrs:{"menu-title":"Categories","color-config":{
            bgColor: this.lobby.tags_menu.background_color,
            primaryTextColor: this.lobby.tags_menu.primary_text_color,
            secondaryTextColor: this.lobby.tags_menu.secondary_text_color,
          },"loading":false,"type":"tag"}}),_c('SidebarMenu',{attrs:{"menu-title":"Favorites","color-config":{
            bgColor: this.lobby.fav_menu.background_color,
            primaryTextColor: this.lobby.fav_menu.primary_text_color,
            secondaryTextColor: this.lobby.fav_menu.secondary_text_color,
          },"loading":false,"type":"fav"}}),_c('SidebarMenu',{attrs:{"menu-title":"Suppliers","color-config":{
            bgColor: this.lobby.brands_menu.background_color,
            primaryTextColor: this.lobby.brands_menu.primary_text_color,
            secondaryTextColor: this.lobby.brands_menu.secondary_text_color,
          },"loading":false,"type":"brand"}})],1)])]),_c('main',{staticClass:"main flex flex-col flex-grow transition-all duration-150 ease-in",staticStyle:{"width":"200px","z-index":"2"}},[_c('div',{staticClass:"call-to-action-wrapper relative overflow-hidden rounded-b-lg w-100 bg-blue-900",style:({
        backgroundImage: ("url(" + (this.lobby.header_section.primary_img) + ")"),
      })},[_c('div',{staticClass:"h-full w-full overflow-hidden bg-fixed"},[_c('img',{staticClass:"d-none d-sm-block absolute object-contain sm:flex w-1/3 h-1/3 top-1/4 right-20 lg:top-16 lg:h-72",attrs:{"src":this.lobby.header_section.secondary_img,"alt":""}}),_c('div',{staticClass:"px-3"},[_c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"hamburger-menu",style:(_vm.showSideBar ? 'visibility: hidden' : '')},[_c('img',{staticStyle:{"height":"20px","width":"20px","cursor":"pointer"},attrs:{"alt":"icon","src":_vm.hamburgerMenu},on:{"click":function($event){return _vm.onClickMenu()}}})]),_c('button',{staticClass:"text-white text-capitalize px-2",staticStyle:{"font-size":"20px"},on:{"click":function($event){_vm.showingInMenu.element = 'brandsPreview'}}},[_vm._v(" Home ")])])])]),_c('div',{staticClass:"flex flex-col mx-10 gap-10"},[_c('div',{staticClass:"row mt-8 align-items-center"},[_c('div',{staticClass:"col-md-7 col-sm-12 col-lg-6 col-xl-5"},[_c('call-to-action-words',{attrs:{"words":this.lobby.header_section.header_title.text,"highlighted-word":'slots',"color-config":{
                  primaryTextColor:
                    this.lobby.header_section.header_title.primary_text_color,
                  secondaryTextColor:
                    this.lobby.header_section.header_title
                      .secondary_text_color,
                }}}),_c('button',{class:[
                  this.lobby.header_section.button_class,
                  'text-white px-2 py-1 rounded-md font--extra-bold shadow-lg mt-5' ],style:(this.lobby.header_section.button_style)},[_vm._v(" Register ")])],1)]),_c('LobbyFeatured',{attrs:{"color-config":{
              backgroundColor: this.lobby.background_color,
              shadowStyle: this.lobby.featured_section.shadow_style,
              primaryTextColor:
                this.lobby.featured_section.primary_text_color,
            }}}),_c('LobbyBestBrands')],1)])]),_c('div',{staticClass:"main-content flex flex-col flex-grow p-4 text-white"},[(_vm.showingInMenu.element === 'game')?_c('LobbyToolbar',{attrs:{"toolbar-title":'all games',"tabs-options":this.lobby.toolbar_menu.menu_options,"color-config":{
          bgColor: this.lobby.toolbar_menu.background_color,
          primaryTextColor: this.lobby.toolbar_menu.primary_text_color,
          secondaryTextColor: this.lobby.toolbar_menu.secondary_text_color,
        }}}):_vm._e(),(_vm.showingInMenu.element === 'brandsPreview')?_c('BrandGamesPreview',{attrs:{"config-color":{
          buttonClass: this.lobby.brands_games_preview.button_class,
          buttonStyle: this.lobby.brands_games_preview.button_style,
          bgColor: this.lobby.brands_games_preview.bg_color,
          borderColor: this.lobby.brands_games_preview.border_color,
        },"loading":false},on:{"clicked":function($event){return _vm.brandPreviewClicked()}}}):(_vm.showingInMenu.element === 'game')?_c('LobbyGames',{attrs:{"color-config":{
          buttonClass: this.lobby.brands_games_preview.button_class,
          buttonStyle: this.lobby.brands_games_preview.button_style,
          bgColor: this.lobby.brands_games_preview.bg_color,
          borderColor: this.lobby.brands_games_preview.border_color,
        }}}):_vm._e(),_c('LobbyCard',{attrs:{"color-config":{
          bgColor: this.lobby.featured_card.background_color,
          primaryTextColor: this.lobby.featured_card.primary_text_color,
          secondaryTextColor: this.lobby.featured_card.secondary_text_color,
        },"card-title":this.lobby.featured_card.title || '',"img":this.lobby.featured_card.img}}),_c('LobbyRandomGames',{attrs:{"color-config":{
          backgroundColor: this.lobby.background_color,
        }}})],1),_c('LobbyFooter'),(_vm.showSideBar)?_c('div',{staticClass:"overlay"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }