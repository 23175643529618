import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL
const GATEWAY_URL = process.env.VUE_APP_GATEWAY

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {

        fetchLobbies(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/lobbies`, {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeLobby(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/lobbies/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

    },
}
