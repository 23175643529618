<script>
import ApostalaLobbyTags from "../../components/modules/lobby/ApostalaLobbyTags.vue";
import ApostalaCarousel from "@/views/components/whitelabel-templates/Apostala/components/common/ApostalaCarousel.vue";
import ApostalaMoreProducts from "@/views/components/whitelabel-templates/Apostala/components/modules/section/more-products/ApostalaMoreProducts.vue";
import ApostalaLobbyProviders from "@/views/components/whitelabel-templates/Apostala/components/modules/section/providers/ApostalaLobbyProviders.vue";
import ApostalaGamesList from "@/views/components/whitelabel-templates/Apostala/components/modules/section/games/ApostalaGamesList.vue";

export default {
  name: "ApostalaHomePage",
  components: {
    ApostalaGamesList,
    ApostalaMoreProducts,
    ApostalaLobbyProviders,
    ApostalaCarousel,
    ApostalaLobbyTags,
  },
  props: ["carousel", "products"],
};
</script>

<template>
  <div class="overflow-hidden relative" ref="homePage">
    <apostala-carousel :carousel="carousel" />

    <!--TODO CHANGE THIS-->
    <apostala-lobby-tags />

    <apostala-lobby-providers />

    <apostala-games-list title="Best games" />

    <apostala-more-products :products="products" />
  </div>
</template>

<style scoped></style>
