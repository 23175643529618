<script>
/* eslint-disable */

import ApostalaGame
  from "@/views/components/whitelabel-templates/Apostala/components/modules/section/games/ApostalaGame.vue";

export default {
  name: "ApostalaGamesRow",
  components: {
    ApostalaGame
  },
  props: {
    games: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      swiperInstance: null,
    };
  },

  mounted() {
    this.swiperInstance = new this.$root.$swiper(this.$refs.swiperGames, {
      autoHeight: true,
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 10,
      speed: 800,
      loopFillGroupWithBlank: true,
      breakpoints: {
        992: {
          slidesPerView: 8,
          slidesPerGroup: 1,
        },
      },
      on: {
        slideChange: swiper => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
  methods: {
    nextSlide() {
      this.swiperInstance.slideNext();
    },
    prevSlide() {
      this.swiperInstance.slidePrev();
    },
  },
};
</script>

<template>
  <div class="apostala-games">
    <div ref="swiperGames" class="swiper apostala-games-swiper">
      <div class="swiper-wrapper">
        <apostala-game
          class="swiper-slide"
          v-for="(game, index) in games"
          :key="index"
          :game="game"
        />
      </div>
      <div
        @click="prevSlide"
        class="apostala-games-header-actions-prev btn btn-dark"
      >
        <svg viewBox="0 0 32 32">
          <path
            d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
          ></path>
        </svg>
      </div>
      <div
        @click="nextSlide"
        class="apostala-games-header-actions-next btn btn-dark"
      >
        <svg viewBox="0 0 32 32">
          <path
            d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../common/assets/mixins";

.apostala-games {
  max-width: 100vw;

  @include responsive(lg) {
    width: 100%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.05rem;
    margin-bottom: 15px;
    color: var(--title-color);

    &-title {
      font-weight: 600;

      @include responsive(lg) {
        font-size: 1.25rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 5px;

      &-prev,
      &-next {
        border: none;
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        padding: 10px 0;
        background-color: color-mix(in srgb, black 80%, transparent 100%);

        @include responsive(lg) {
          display: flex;
        }

        svg {
          width: 25px;
          height: 29px;

          path {
            fill: red;
          }
        }
      }

      &-prev {
        left: 10px;
        svg {
          rotate: -180deg;
        }
      }

      &-next {
        right: 10px;
      }
    }
  }

  .swiper-slide {
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
