<script>
import store from "@/store";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";

export default {
  props: {
    sidebar: {
      default: {}
    }
  },
  data() {
    return {
      buttonsRefs: [],
    };
  },
  updated() {
    console.log(`El componente KBSidebar se ha actualizado`);
  },

  setup() {
    const MODULE_KB_STORE_NAME = "kb-store";
    // Register module
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    return {}
  },

  computed: {
    
  },
  methods: {
    TOGGLE_SIDEBAR() {
    },
    parseSVG(svgData) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(svgData, "image/svg+xml");
      const svgNode = svgDocument.documentElement;
      return svgNode.innerHTML;
    },

    setHover($event, color) {
      const element = $event.target
      if ($event.type === 'mouseenter') {
        element.style = "background: " + color + " !important"
      } else {
        element.style = ""
      }
    },

    editItem(item) {
      store.commit(
          "kb-store/SET_CLICKED_SIDEBAR_LINK", item
      );
    },

    sortLinks() {
      const links = this.sidebar.links
      if (links.length > 0) {
        links.sort((a, b) => Number(a.position) - Number(b.position));
      }
      this.sidebar.links = links
    }

  },

  mounted() {
    this.sortLinks()
  }


};
</script>

<template>
  <div class="kb-sidebar"
       :class="{ open: false }">
    <div class="kb-sidebar-head">
      <button
          @click="TOGGLE_SIDEBAR()"
          class="btn"
          aria-expanded="false"
      >
        <svg viewBox="0 0 32 32">
          <path
              d="M26.494 22.792c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988zM26.494 14.384c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988zM26.494 5.004c1.145 0 2.074 0.941 2.074 2.102 0 1.103-0.838 2.007-1.903 2.095l-0.17 0.007h-20.988c-1.145 0-2.074-0.941-2.074-2.102 0-1.103 0.838-2.007 1.903-2.095l0.17-0.007h20.988z"
          ></path>
        </svg>
      </button>
    </div>
    <div class="kb-sidebar-menu">
      <a
          v-for="(item, index) in sidebar.links"
          :key="index"
          href="javascript:void(0)"
          @click="editItem(item)"
          class="btn"
          ref="buttonRef => buttonsRefs[index]"
      >
        <svg viewBox="0 0 32 32" v-html="parseSVG(item.svgData)"></svg>
        <span class="btn-text">
          {{ item.title }}
        </span>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins";

.kb-sidebar {
  background-color: var(--layout-sidebar-bg);
  transition: var(--transition);
  overflow: hidden;
  max-height: 100dvh;
  z-index: 100;
  position: fixed;
  top: calc(100% - var(--header-height));
  left: 0;
  width: 100%;

  @include responsive(lg) {
    box-shadow: 0 0 15px var(--shadow-color);
    grid-row: 1 / span 2;
    position: static;
    top: auto;
    transform: none !important;
  }

  &.open {
    transform: translateY(-100%);

    @include responsive(lg) {
      .kb-sidebar-head {
        justify-content: flex-start;
      }

      .btn {

        &-text {
          margin-left: 0;
          opacity: 1;
          transform: translate(0, -50%);
        }
      }
    }
  }

  @include btn;

  .btn {
    background-color: var(--layout-sidebar-btn-bg);
    color: var(--layout-sidebar-btn-color);
    fill: var(--layout-sidebar-btn-color) !important;

    &:hover {
      background-color: var(--layout-sidebar-btn-bg-hover);
    }
  }


  &-head {
    display: none;
    align-items: center;
    height: var(--header-height);
    padding: 10px;
    box-shadow: 0 0 15px color-mix(in srgb, var(--shadow-color) 70%, transparent 100%);

    @include responsive(lg) {
      display: flex;
    }
  }

  &-menu {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 10px 10px 40px 10px;
    height: calc(100dvh - var(--header-height) * 2);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include responsive(lg) {
      height: calc(100dvh - var(--header-height));
      padding: 10px;
    }
  }
}
</style>
